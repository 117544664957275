/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.page-venues .venues__has-seating-icon {
  width: 13px; }

.page-venues .table__head-title--right .table__sortable {
  justify-content: flex-end;
  padding-right: 0; }

.page-venues .table__head-title--center {
  text-align: center; }
