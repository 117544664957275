/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.image-upload__drop-area {
  position: relative;
  border-radius: 3px;
  border: 2px dashed #eeeef3;
  text-align: center;
  padding: 8px;
  font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
  color: #848692; }
  .image-upload__drop-area-button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-right: 8px; }
  .image-upload__drop-area input {
    display: none; }

.image-upload__preview {
  overflow: hidden;
  margin-top: 16px;
  margin-right: 16px;
  display: inline-block;
  width: 120px;
  height: 120px;
  background-size: cover;
  background-position: center center;
  border-radius: 3px; }
  .image-upload__preview:hover .image-upload__preview-overlay {
    opacity: 1; }
  .image-upload__preview-overlay {
    display: flex;
    opacity: 0;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(61, 62, 66, 0.8); }
    .image-upload__preview-overlay-action {
      cursor: pointer;
      fill: #ffffff;
      background: transparent;
      border: none; }
