/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start; }
  @media (max-width: 1030px) {
    .content {
      flex-direction: column; } }
