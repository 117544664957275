/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

@media (max-width: 850px) {
  .tickets-overview-widget .widget__content {
    overflow: auto; } }

.tickets-overview-widget__content {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-direction: column; }
  @media (max-width: 850px) {
    .tickets-overview-widget__content {
      width: 800px;
      min-width: 800px;
      max-width: 800px; } }

.tickets-overview-widget__totals-item {
  margin-bottom: 0px; }

.tickets-overview-widget__chart {
  display: flex;
  flex-direction: column;
  min-height: 456px; }

.tickets-overview-widget__revenue {
  font-weight: 700; }

.tickets-overview-widget__tickets {
  color: #de102d;
  font-weight: 700; }
