/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.image-editor {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 0.72fr 0.28fr;
  grid-template-rows: auto -webkit-max-content -webkit-min-content;
  grid-template-rows: auto max-content min-content;
  grid-template-areas: "canvas canvas" "controls meta" "actions meta";
  grid-column-gap: 32px;
  grid-row-gap: 16px;
  min-height: 64px; }
  @media (max-width: 1030px) {
    .image-editor {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas: "canvas" "controls" "actions" "meta"; } }
  .image-editor__canvas {
    position: relative;
    grid-area: canvas; }
    .image-editor__canvas-cropper {
      display: block;
      width: 100%;
      height: 374px;
      background: #fafafb;
      box-shadow: inset 0px 0px 0px 1px #eeeef3;
      overflow: hidden; }
      .image-editor__canvas-cropper .cropper-crop-box-natural-dimensions {
        display: block;
        position: absolute;
        top: -13px;
        left: 0;
        font-size: 9px;
        line-height: 1;
        font-weight: 400;
        letter-spacing: 0.5px;
        color: #fff;
        white-space: nowrap;
        padding: 2px 4px;
        background: rgba(51, 153, 255, 0.7);
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
        pointer-events: none; }
        .image-editor__canvas-cropper .cropper-crop-box-natural-dimensions[data-position=top-out] {
          top: -13px; }
        .image-editor__canvas-cropper .cropper-crop-box-natural-dimensions[data-position=top-in] {
          top: 0px; }
    .image-editor__canvas-loader {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center; }
  .image-editor__meta {
    grid-area: meta; }
    .image-editor__meta-item {
      margin: 0 0 4px;
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
      color: #848692; }
      .image-editor__meta-item-title {
        font-weight: 700; }
  .image-editor__controls {
    grid-area: controls;
    margin: 0 0 8px; }
    .image-editor__controls .button {
      margin: 0 8px 8px 0; }
    .image-editor__controls-button {
      padding: 12px; }
      .image-editor__controls-button:last-child {
        margin-right: 0; }
      .image-editor__controls-button .button__icon {
        width: 16px;
        height: 16px; }
        .image-editor__controls-button .button__icon svg {
          width: 16px;
          height: 16px;
          fill: #3d3e42; }
      .image-editor__controls-button:hover .button__icon svg, .image-editor__controls-button:active .button__icon svg, .image-editor__controls-button:focus .button__icon svg {
        fill: #3d3e42; }
      .image-editor__controls-button.button--disabled .button__icon svg {
        fill: #ffffff; }
  .image-editor__actions {
    grid-area: actions; }
  .image-editor__loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.75);
    z-index: 1; }
