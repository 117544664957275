/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.gallery {
  position: relative;
  display: block;
  width: 100%;
  min-height: 104px; }
  .gallery--dragging {
    cursor: move; }
  .gallery__loader {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.5); }
  .gallery__grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%; }
  .gallery__info {
    padding: 8px 0;
    color: #848692;
    font-size: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .gallery__item {
    position: relative;
    width: 27.83%;
    min-width: 88px;
    max-width: 140px;
    margin-right: 16px;
    margin-bottom: 16px;
    background: #ffffff; }
    .gallery__item--dragged {
      box-shadow: 0px 0px 0px 2px #ffffff; }
      .gallery__item--dragged .gallery__item-move {
        display: block; }
    .gallery__item:after {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 1px solid #eeeef3;
      pointer-events: none; }
    .gallery__item--featured:after {
      border: 2px solid #de102d; }
    .gallery__item-move {
      display: none;
      position: absolute;
      top: 8px;
      left: 8px;
      width: 16px;
      height: 16px; }
      .gallery__item-move-icon {
        display: block;
        width: 16px;
        height: 16px;
        fill: #ffffff; }
    .gallery__item-inner {
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden; }
    .gallery__item-content {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover; }
      .gallery__item-content:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: #000;
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
        z-index: 1; }
        .gallery__item:hover .gallery__item-content:before {
          opacity: 0.5; }
    .gallery__item-file-type {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 48px;
      height: 64px;
      padding: 4px 8px;
      margin: 0 auto;
      font-weight: 400;
      text-transform: lowercase;
      font-size: 13px;
      line-height: 14px;
      letter-spacing: 0.5px;
      text-align: left;
      border-radius: 3px;
      background: #848692;
      color: rgba(255, 255, 255, 0.8); }
      .gallery__item-file-type[data-file-type="pdf"] {
        background: #c03630; }
      .gallery__item-file-type[data-file-type="zip"], .gallery__item-file-type[data-file-type="zipx"], .gallery__item-file-type[data-file-type="rar"], .gallery__item-file-type[data-file-type="tar"], .gallery__item-file-type[data-file-type="7z"] {
        background: #d78e0f; }
      .gallery__item-file-type[data-file-type="xls"], .gallery__item-file-type[data-file-type="xlsx"], .gallery__item-file-type[data-file-type="xlsm"], .gallery__item-file-type[data-file-type="xlsb"], .gallery__item-file-type[data-file-type="ods"] {
        background: #217346; }
      .gallery__item-file-type[data-file-type="ppt"], .gallery__item-file-type[data-file-type="pptx"], .gallery__item-file-type[data-file-type="pptm"] {
        background: #b7472a; }
      .gallery__item-file-type[data-file-type="doc"], .gallery__item-file-type[data-file-type="docx"], .gallery__item-file-type[data-file-type="docm"], .gallery__item-file-type[data-file-type="odt"] {
        background: #2b579a; }
      .gallery__item-file-type:before {
        content: " ";
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom-left-radius: 2px;
        border-width: 8px;
        border-style: solid;
        border-color: #fff #fff rgba(255, 255, 255, 0.35) rgba(255, 255, 255, 0.35); }
      .gallery__item-file-type:after {
        display: block;
        content: attr(data-file-type);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 4px 6px;
        word-break: break-all;
        overflow: hidden;
        box-sizing: inherit; }
    .gallery__item-actions {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
      pointer-events: none;
      visibility: hidden;
      z-index: 2; }
      .gallery__item:hover .gallery__item-actions {
        opacity: 1;
        transition-delay: 0.1s;
        -webkit-animation: delay-pointer-events 0.1s linear;
                animation: delay-pointer-events 0.1s linear;
        pointer-events: auto;
        visibility: visible; }
      .gallery__item-actions:empty,
      .gallery--dragging .gallery__item-actions {
        pointer-events: none !important;
        opacity: 0 !important; }
    .gallery__item-action {
      position: relative;
      display: block;
      flex: 0 1 auto;
      align-items: center;
      max-width: 100%;
      margin: 0;
      padding: 8px;
      font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
      border: none;
      border-radius: 50%;
      text-transform: none;
      text-align: center;
      text-decoration: none;
      fill: #ffffff;
      background-image: none;
      background-color: transparent;
      vertical-align: middle;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      touch-action: manipulation;
      cursor: pointer;
      white-space: nowrap;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
      .gallery__item-action--active, .gallery__item-action:hover {
        background-color: #ff3e43; }
      .gallery__item-action-icon {
        display: block;
        width: 16px;
        height: 16px; }
        .gallery__item-action-icon svg {
          display: block;
          width: 16px;
          height: 16px;
          fill: #ffffff; }
    .gallery__item-progress {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%; }
      .gallery__item-progress-bar {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(238, 238, 243, 0.9);
        transition: height 0.2s; }
      .gallery__item-progress-value {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: block;
        width: 40px;
        height: 40px;
        font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        background: #81dbbe;
        border-radius: 100%;
        white-space: nowrap;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
        .gallery__item-progress--fail .gallery__item-progress-value {
          background: #ff3e43; }
        .gallery__item-progress-value-icon {
          display: block;
          margin: 12px auto 0;
          width: 16px;
          height: 16px; }
          .gallery__item-progress-value-icon svg {
            display: block;
            width: 16px;
            height: 16px;
            fill: #ffffff; }
  .gallery__upload {
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #bbb;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    background: rgba(255, 255, 255, 0.9);
    z-index: 1; }
    .gallery__upload-receiver {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%; }
    .gallery__upload--drag-entered {
      border: 2px dashed #ff3e43; }
    .gallery__upload-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      cursor: pointer; }
      .gallery__upload-button-icon {
        display: block;
        position: relative;
        font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
        transform: scale(1);
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
        .gallery__upload--drag-entered .gallery__upload-button-icon,
        .gallery__upload:hover .gallery__upload-button-icon,
        .gallery__upload:hover .gallery__upload-button-icon,
        .gallery__upload:active .gallery__upload-button-icon,
        .gallery__upload:focus .gallery__upload-button-icon {
          color: #ff3e43; }
    .gallery__upload input {
      display: none; }

@-webkit-keyframes delay-pointer-events {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes delay-pointer-events {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  100% {
    visibility: visible; } }
