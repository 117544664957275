/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.event-tables__text {
  font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
  padding: 8px; }
