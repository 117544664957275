/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

@media (max-width: 480px) {
  .event-sales-per-ticket-type table {
    width: 500px; } }

.event-sales-per-ticket-type .listing {
  margin-bottom: 0; }
  .event-sales-per-ticket-type .listing__body {
    overflow: hidden; }
    @media (max-width: 480px) {
      .event-sales-per-ticket-type .listing__body {
        overflow: auto; } }

.event-sales-per-ticket-type__details-button {
  height: 25px;
  padding: 0; }

.event-sales-per-ticket-type__back-button {
  padding: 0;
  margin-right: 8px;
  height: auto; }
  .event-sales-per-ticket-type__back-button svg {
    fill: #3d3e42 !important; }

.event-sales-per-ticket-type .widget__content {
  flex-direction: row;
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
  transition-duration: 0.6s; }
  .event-sales-per-ticket-type .widget__content > div {
    flex-basis: 100%;
    max-width: 100%;
    min-width: 100%; }
    .event-sales-per-ticket-type .widget__content > div:first-child {
      margin-right: 32px; }

.event-sales-per-ticket-type--animate-details .widget__content {
  transform: translate3d(-102%, 0, 0); }
