/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.location {
  flex: 1 1;
  display: block;
  width: 100%;
  position: relative;
  margin: 0 16px 0 0; }
  .location:last-child {
    margin-right: 0px; }
  .location__dropdown {
    left: 0;
    right: 0;
    top: 100%;
    margin: 0;
    padding: 0;
    z-index: 10;
    width: 100%;
    display: block;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    position: absolute;
    border-radius: 3px;
    list-style-type: none;
    background-color: #ffffff;
    box-shadow: 0 0 6px 0 rgba(49, 69, 141, 0.1); }
  .location__dropdown-item {
    width: 100%;
    display: block; }
  .location__dropdown-item--active .location__dropdown-btn, .location__dropdown-item:hover .location__dropdown-btn {
    color: #848692;
    background-color: #eeeef3; }
  .location__dropdown-btn {
    margin: 0;
    width: 100%;
    border: none;
    display: block;
    cursor: pointer;
    border-radius: 0;
    text-align: left;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    -webkit-appearance: none;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    color: #aaaabd;
    background-color: transparent;
    padding: 8px 16px; }
  .location__dropdown-message {
    margin: 0;
    width: 100%;
    border: none;
    display: block;
    border-radius: 0;
    text-align: left;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    -webkit-appearance: none;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    color: #aaaabd;
    background-color: transparent;
    padding: 8px 16px; }
  .location__checkbox {
    width: 100%;
    display: block; }
    .location__checkbox .input-checkbox {
      margin-bottom: 0; }
