/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.floating-notifications {
  position: fixed;
  display: block;
  left: 16px;
  bottom: 16px;
  width: 100%;
  max-width: 280px;
  z-index: 99999; }
  .floating-notifications:empty, .floating-notifications--empty {
    pointer-events: none; }
