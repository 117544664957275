/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.events-toggle {
  font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
  display: flex; }
  .events-toggle__disabled {
    color: #848692; }
  .events-toggle :last-child {
    margin-left: 15px; }
