/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.pagination {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: #848692; }
  @media (max-width: 1030px) {
    .pagination {
      margin-top: 16px; } }
  .pagination__button {
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692;
    padding: 0;
    position: relative;
    min-width: 24px;
    background: transparent;
    border: none;
    z-index: 2;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .pagination__button .svg {
      width: 8px;
      fill: #3d3e42; }
    .pagination__button:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      opacity: 1;
      min-width: 24px;
      height: 24px;
      border-radius: 12px;
      background: #eeeef3;
      z-index: -1;
      transform: scale(0);
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .pagination__button--arrow {
      margin: 0 4px;
      color: #3d3e42; }
      .pagination__button--arrow svg {
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
      .pagination__button--arrow:hover:after {
        opacity: 0.5; }
      .pagination__button--arrow:hover svg {
        fill: #de102d; }
    .pagination__button--active {
      font-weight: bold;
      color: #3d3e42; }
    .pagination__button--arrow:after, .pagination__button--active:after {
      transform: scale(1); }
    .pagination__button--disabled {
      pointer-events: none; }
    .pagination__button:hover {
      color: #de102d;
      cursor: pointer; }
