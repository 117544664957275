/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.input {
  flex: 1 1;
  display: block;
  width: 100%;
  position: relative;
  margin: 0 16px 0 0; }
  .input:last-child {
    margin-right: 0px; }
  .input__helper-text {
    position: relative;
    bottom: -4px;
    display: inline-block;
    width: 100%;
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    padding-left: 16px;
    margin-top: 0px;
    color: #848692; }
    .input__helper-text--error {
      color: #fb6f75; }
  .input__field {
    margin: 0;
    z-index: 1;
    width: 100%;
    height: 40px;
    resize: none;
    display: block;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    padding: 0 48px 0 16px;
    border-radius: 3px;
    position: relative;
    font-weight: normal;
    -webkit-appearance: none;
    color: #3d3e42;
    border: 2px solid #eeeef3;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .input__field::-ms-clear, .input__field::-ms-reveal {
      display: none; }
    .input__field--textarea {
      height: auto;
      min-height: 120px;
      padding: 12px 48px 12px 18px; }
    .input__field--signature {
      height: auto;
      overflow: hidden; }
      .input__field--signature canvas {
        margin-left: -16px; }
    .input__field:-webkit-autofill, .input__field:-webkit-autofill:hover, .input__field:-webkit-autofill:active, .input__field:-webkit-autofill:focus {
      box-shadow: inset 0 0 0 1000px #ffffff !important; }
      .input__field:-webkit-autofill ~ .input__label, .input__field:-webkit-autofill:hover ~ .input__label, .input__field:-webkit-autofill:active ~ .input__label, .input__field:-webkit-autofill:focus ~ .input__label {
        font-size: 11px;
        background-color: white;
        transform: translate(15px, -11px); }
  .input__label {
    top: 0;
    left: 0;
    z-index: 2;
    padding: 5px;
    max-width: calc(100% - 40px);
    line-height: 1;
    font-size: 16px;
    position: absolute;
    pointer-events: none;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    color: #aaaabd;
    transform: translate(15px, 7px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .button--action ~ .input__label {
      max-width: calc(100% - 92px); }
  .input__action {
    min-width: 48px;
    max-height: 40px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    line-height: 1;
    display: flex;
    cursor: pointer;
    padding: 0 16px;
    flex-wrap: nowrap;
    position: absolute;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center; }
    .input__action ~ .input__action {
      right: 32px;
      padding-right: 0; }
    .input__action .svg {
      width: 16px;
      fill: #3d3e42; }
    .input__action--clear .svg {
      width: 8px;
      fill: #848692; }
  .input__inner {
    display: block;
    position: relative; }
  .input__subtext {
    display: block;
    font-size: 11px;
    padding: 8px 21px;
    color: #848692; }
  .input--focused .input__field,
  .input--focused .react-select__control {
    outline: none;
    border: 2px solid #848692; }
  .input--focused .input__label {
    font-size: 11px;
    background-color: white;
    transform: translate(15px, -11px); }
  .input__icon {
    top: 0;
    max-height: 40px;
    left: 8px;
    bottom: 0;
    z-index: 3;
    line-height: 1;
    display: flex;
    cursor: pointer;
    padding: 5px 8px;
    flex-wrap: nowrap;
    position: absolute;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    pointer-events: none; }
    .input__icon .svg {
      fill: #3d3e42; }
    .input__icon--right ~ .input__action {
      right: 32px;
      padding-right: 0; }
  .input--icon-left .input__field,
  .input--icon-left .react-select__control {
    padding-left: 48px; }
  .input--icon-left .react-select__input {
    padding-left: 30px; }
  .input--icon-left .location__dropdown-btn {
    padding-left: 48px; }
  .input--icon-left .input__label {
    left: 32px; }
  .input--icon-right .input__icon {
    left: auto;
    right: 8px; }
  .input--active .input__label {
    max-width: calc(100% - 18px);
    font-size: 11px;
    background-color: white;
    transform: translate(15px, -11px); }
  .input--active .button--action ~ .input__label {
    max-width: calc(100% - 80px); }
  .input--active.input--disabled .input__label {
    background-color: transparent; }
  .input--disabled {
    opacity: 0.6; }
    .input--disabled .input__field {
      background-color: #eeeef3;
      border: 2px solid #eeeef3; }
  .input--error .input__field {
    border: 2px solid #fb6f75; }
  .input--required:after {
    content: '*';
    position: absolute;
    right: -16px;
    top: 0px;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    color: #aaaabd; }
