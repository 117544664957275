/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.page-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000; }
  .page-loader .svg {
    width: 80px;
    height: 80px; }
  .page-loader .st0 {
    fill: #ff778b; }
  .page-loader .st1 {
    fill: #ff8899; }
  .page-loader .st2 {
    fill: #ffd1d8; }
  .page-loader .st3 {
    fill: #ffb3be; }
