/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.input-number {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 16px; }
  .input-number__error {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    width: 100%;
    margin-left: 16px;
    margin-top: 8px;
    color: #fb6f75; }
  .input-number__col {
    width: 64px; }
    .input-number__col.is-btn {
      width: 40px; }
  .input-number__field {
    margin: 0;
    width: 100%;
    border: none;
    height: 40px;
    padding: 0 3px;
    font-size: 16px;
    border-radius: 0;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    color: #3d3e42; }
    .input-number__field::-webkit-outer-spin-button, .input-number__field::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none; }
  .input-number__btn {
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    flex-wrap: nowrap;
    border-radius: 2px;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    border: 2px solid #eeeef3; }
