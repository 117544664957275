/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.sales-cycle__content {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-direction: column; }

.sales-cycle__main {
  display: flex;
  flex-basis: 70%;
  flex-direction: column;
  padding-right: 24px;
  min-height: 296px; }

.sales-cycle__attendance {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px; }
  .sales-cycle__attendance-chart {
    position: relative;
    display: flex;
    flex-basis: 100%;
    height: auto;
    margin-bottom: 16px; }
    .sales-cycle__attendance-chart-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      pointer-events: none; }
    .sales-cycle__attendance-chart-data {
      position: relative;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      height: 100%;
      text-align: center; }
    .sales-cycle__attendance-chart-total {
      font: 700 16px/20px "Roboto", Helvetica, Arial, sans-serif;
      transform: translate3d(0, -100%, 0);
      margin: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center; }
  .sales-cycle__attendance-data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
    .sales-cycle__attendance-data-item {
      margin-bottom: 8px;
      margin-right: 24px; }
      .sales-cycle__attendance-data-item:last-child {
        margin-bottom: 0; }

.sales-cycle__totals-title {
  margin-bottom: 0; }

.sales-cycle__totals-value {
  font: 700 16px/20px "Roboto", Helvetica, Arial, sans-serif;
  margin-bottom: 0; }

.sales-cycle__item {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; }
  .sales-cycle__item-icon {
    display: flex;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    border-radius: 50%; }
  .sales-cycle__item-label {
    display: flex;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
