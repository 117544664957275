/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.switch-card {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
  border: 2px solid #eeeef3;
  padding: 16px;
  cursor: pointer; }
  .switch-card--small-padding {
    padding-top: 9px;
    padding-bottom: 9px; }
  .switch-card__title {
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    min-width: calc(100% - 40px);
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0; }
  .switch-card__description {
    width: 100%;
    min-width: 100%;
    margin: 16px 0 0; }
  .switch-card__action {
    width: 32px;
    min-width: 32px;
    margin-left: 8px; }
  .switch-card__input {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    margin: 0; }
    .switch-card__input-error {
      font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
      width: 100%;
      margin-left: 16px;
      margin-top: 8px;
      color: #fb6f75; }
    .switch-card__input-toggle {
      width: 32px;
      height: 16px;
      display: block;
      font-size: 13px;
      cursor: pointer;
      border-radius: 8px;
      position: relative;
      background-color: white;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      border: 2px solid #eeeef3; }
      .switch-card__input-toggle:before {
        top: -2px;
        left: -2px;
        z-index: 2;
        width: 16px;
        height: 16px;
        content: '\A';
        position: absolute;
        border-radius: 100%;
        transform: translateX(0px);
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
        background-color: #848692; }
    .switch-card__input-toggle--active:before {
      transform: translateX(16px); }
    .switch-card__input-toggle--green {
      border: 2px solid #81dbbe;
      background-color: #81dbbe; }
    .switch-card__input-checkbox {
      display: none; }
