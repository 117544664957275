/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

@media (max-width: 850px) {
  .total-sales-by-date-widget .widget__content {
    overflow: auto; } }

.total-sales-by-date-widget__content {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-direction: row; }
  @media (max-width: 850px) {
    .total-sales-by-date-widget__content {
      width: 800px;
      min-width: 800px;
      max-width: 800px; } }

.total-sales-by-date-widget .bar-chart {
  max-height: 440px; }

.total-sales-by-date-widget__main {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding-right: 24px;
  min-height: 296px; }
