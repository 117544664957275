/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.print-guestlist-page {
  width: 100%;
  margin: 0; }
  .print-guestlist-page__data {
    width: 95%;
    margin: 0 auto;
    background-color: #ffffff; }
    .print-guestlist-page__data tbody tr:nth-child(2n) {
      background: #efefef; }
    .print-guestlist-page__data th {
      padding: 8px;
      text-align: left; }
    .print-guestlist-page__data-title th {
      padding: 8px;
      background: #ffffff;
      border: 1px solid #ffffff;
      color: #3d3e42;
      font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .print-guestlist-page__data-header th {
      padding: 8px;
      background: #3d3e42;
      border: 1px solid #3d3e42;
      color: #fff;
      text-transform: uppercase;
      font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
      .print-guestlist-page__data-header th:last-child {
        text-align: right; }
    .print-guestlist-page__data td {
      padding: 8px;
      border-top: 1px solid #3d3e42;
      border-bottom: 1px solid #3d3e42;
      text-align: left; }
      .print-guestlist-page__data td:last-child {
        text-align: right; }
    .print-guestlist-page__data-table td {
      border-left: none;
      page-break-inside: avoid; }
      .print-guestlist-page__data-table td.print-guestlist-page__data-table-cell-bold {
        font-weight: 700; }
      .print-guestlist-page__data-table td:first-child {
        border-left: 1px solid #3d3e42; }
      .print-guestlist-page__data-table td.print-guestlist-page__data-table-cell-borders {
        border-left: 1px solid #3d3e42;
        border-right: 1px solid #3d3e42; }
      .print-guestlist-page__data-table td:last-child {
        border-right: 1px solid #3d3e42; }
      .print-guestlist-page__data-table td p {
        margin-bottom: 8px; }
        .print-guestlist-page__data-table td p:last-child {
          margin-bottom: 0; }
    .print-guestlist-page__data-footer td {
      border: none; }
      .print-guestlist-page__data-footer td:last-child {
        text-align: right; }
