/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.get-out-tickets {
  width: 100%; }
  .get-out-tickets__title {
    margin-bottom: 8px;
    display: flex;
    align-items: center; }
  .get-out-tickets__statistic-value {
    margin-bottom: 16px;
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center; }
  .get-out-tickets__statistic-difference {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin-left: 8px;
    margin-top: 4px; }
  .get-out-tickets__text {
    margin-bottom: 8px; }
    .get-out-tickets__text-bold {
      font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif; }
    .get-out-tickets__text:last-of-type {
      margin-bottom: 24px; }
