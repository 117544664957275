/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.front-main {
  width: 50%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  justify-content: center; }
  @media (max-width: 1030px) {
    .front-main {
      width: 100%;
      padding: 32px; } }
  .front-main__content {
    width: 100%;
    max-width: 432px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: stretch;
    justify-content: center; }
  .front-main__title {
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0px; }
  .front-main__subtitle {
    margin: 8px 0 0 0; }
  .front-main .form {
    margin-top: 24px; }
    .front-main .form:first-child {
      margin-top: 0px; }
  .front-main .page-notification {
    padding-top: 24px; }
  .front-main .notification {
    margin: 24px 0 0 0; }
    .front-main .notification:first-child {
      margin-top: 0; }
  .front-main .page-content {
    padding-top: 24px; }
