/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.page-events .table-mobile__list-subtitle-item {
  display: flex;
  align-items: center; }
  .page-events .table-mobile__list-subtitle-item .tooltip {
    margin-left: 44px; }
  .page-events .table-mobile__list-subtitle-item .chart__legend {
    white-space: nowrap;
    padding-left: 8px; }

.page-events .table-mobile__list-item--active .table-mobile__additional {
  display: block; }
  .page-events .table-mobile__list-item--active .table-mobile__additional--has-bulk-actions {
    padding: 16px 0 0 40px; }
  .page-events .table-mobile__list-item--active .table-mobile__additional .chart__pie-container .chart__legend {
    white-space: nowrap;
    padding-left: 8px; }
  .page-events .table-mobile__list-item--active .table-mobile__additional .table-mobile__table-row:not(:first-of-type) {
    padding-top: 16px; }

.page-events .table-mobile__list-item--active .table-mobile__table-row {
  display: flex;
  align-items: center; }
