/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.button__icon .button .element-loader .svg .dot1, .button__icon svg .dot1, .button .element-loader .dot1 {
  fill: rgba(255, 255, 255, 0.5); }

.button__icon .button .element-loader .svg .dot2, .button__icon svg .dot2, .button .element-loader .dot2 {
  fill: rgba(255, 255, 255, 0.3); }

.button__icon .button .element-loader .svg .dot3, .button__icon svg .dot3, .button .element-loader .dot3 {
  fill: white; }

.button--secondary .button__icon svg .dot1, .button--secondary .element-loader .dot1, .button--tertiary .button__icon svg .dot1, .button--tertiary .element-loader .dot1 {
  fill: rgba(222, 16, 45, 0.7); }

.button--secondary .button__icon svg .dot2, .button--secondary .element-loader .dot2, .button--tertiary .button__icon svg .dot2, .button--tertiary .element-loader .dot2 {
  fill: rgba(222, 16, 45, 0.4); }

.button--secondary .button__icon svg .dot3, .button--secondary .element-loader .dot3, .button--tertiary .button__icon svg .dot3, .button--tertiary .element-loader .dot3 {
  fill: #de102d; }

.button__inner-button, .button__toggle {
  border: none;
  font: inherit;
  text-transform: inherit;
  text-align: inherit;
  text-decoration: none;
  color: inherit;
  background-image: none;
  background-color: transparent;
  vertical-align: inherit;
  -webkit-user-select: inherit;
     -moz-user-select: inherit;
      -ms-user-select: inherit;
          user-select: inherit;
  touch-action: inherit;
  cursor: inherit;
  white-space: inherit; }

.button {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  align-items: center;
  max-width: 100%;
  height: 40px;
  margin: 0 16px 0 0;
  padding: 12px 16px;
  border: none;
  border-radius: 3px;
  font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif;
  text-transform: none;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  fill: #ffffff;
  background-image: none;
  background-color: #de102d;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
  .button:hover, .button:active, .button:focus {
    text-decoration: none;
    color: #ffffff;
    background: #ff3e43; }
  .button:disabled, .button--disabled {
    background-color: #dadae4;
    cursor: not-allowed; }
    .button:disabled:hover, .button:disabled:active, .button:disabled:focus, .button--disabled:hover, .button--disabled:active, .button--disabled:focus {
      text-decoration: none;
      color: #ffffff;
      background-color: #dadae4; }
  .button:last-child {
    margin-right: 0; }
  .button__label {
    display: inline-block;
    margin-right: 8px; }
    .button__label:last-child {
      margin-right: 0; }
  .button__icon {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: middle;
    fill: #ffffff; }
    .button__icon:last-child {
      margin-right: 0; }
    .button__icon .button .element-loader .svg,
    .button__icon svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: #ffffff; }
    .button__icon:last-child {
      margin-right: 0; }
  .button .element-loader {
    margin: 0 auto; }
  .button--secondary {
    color: #aaaabd;
    background-color: transparent; }
    .button--secondary:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 2px solid #aaaabd;
      border-radius: 3px;
      z-index: 1;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      pointer-events: none; }
    .button--secondary:hover, .button--secondary:active, .button--secondary:focus {
      text-decoration: none;
      color: #aaaabd;
      background-color: transparent; }
      .button--secondary:hover:before, .button--secondary:active:before, .button--secondary:focus:before {
        border: 2px solid #ff3e43; }
      .button--secondary:hover .button__icon, .button--secondary:active .button__icon, .button--secondary:focus .button__icon {
        fill: #aaaabd; }
        .button--secondary:hover .button__icon svg, .button--secondary:active .button__icon svg, .button--secondary:focus .button__icon svg {
          fill: #aaaabd; }
    .button--secondary .button__icon {
      fill: #aaaabd; }
      .button--secondary .button__icon svg {
        fill: #aaaabd; }
    .button--secondary:disabled, .button--secondary.button--disabled {
      color: #ffffff;
      fill: #ffffff;
      background-color: #dadae4;
      cursor: not-allowed; }
      .button--secondary:disabled:before, .button--secondary.button--disabled:before {
        border: 2px solid #dadae4; }
      .button--secondary:disabled:hover, .button--secondary:disabled:active, .button--secondary:disabled:focus, .button--secondary.button--disabled:hover, .button--secondary.button--disabled:active, .button--secondary.button--disabled:focus {
        color: #ffffff;
        fill: #ffffff;
        background-color: #dadae4; }
        .button--secondary:disabled:hover:before, .button--secondary:disabled:active:before, .button--secondary:disabled:focus:before, .button--secondary.button--disabled:hover:before, .button--secondary.button--disabled:active:before, .button--secondary.button--disabled:focus:before {
          border: 2px solid #dadae4; }
      .button--secondary:disabled .button__icon, .button--secondary.button--disabled .button__icon {
        fill: #ffffff; }
        .button--secondary:disabled .button__icon svg, .button--secondary.button--disabled .button__icon svg {
          fill: #ffffff; }
  .button--tertiary {
    color: #de102d;
    background-color: transparent; }
    .button--tertiary:hover, .button--tertiary:active, .button--tertiary:focus {
      text-decoration: none;
      color: #ff3e43;
      background-color: transparent; }
      .button--tertiary:hover .button__icon, .button--tertiary:active .button__icon, .button--tertiary:focus .button__icon {
        fill: #ff3e43; }
        .button--tertiary:hover .button__icon svg, .button--tertiary:active .button__icon svg, .button--tertiary:focus .button__icon svg {
          fill: #ff3e43; }
    .button--tertiary .button__icon {
      fill: #de102d; }
      .button--tertiary .button__icon svg {
        fill: #de102d; }
    .button--tertiary:disabled, .button--tertiary.button--disabled {
      color: #ffffff;
      fill: #ffffff;
      background-color: #dadae4;
      cursor: not-allowed; }
      .button--tertiary:disabled:hover, .button--tertiary:disabled:active, .button--tertiary:disabled:focus, .button--tertiary.button--disabled:hover, .button--tertiary.button--disabled:active, .button--tertiary.button--disabled:focus {
        color: #ffffff;
        fill: #ffffff;
        background-color: #dadae4; }
      .button--tertiary:disabled .button__icon, .button--tertiary.button--disabled .button__icon {
        fill: #ffffff; }
        .button--tertiary:disabled .button__icon svg, .button--tertiary.button--disabled .button__icon svg {
          fill: #ffffff; }
  .button--small {
    height: 28px;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    padding: 5px 8px;
    margin: 0 8px 0 0; }
    .button--small:before {
      border-width: 1px; }
    .button--small .button__icon {
      width: 16px;
      height: 16px;
      vertical-align: middle; }
    .button--small .button__icon,
    .button--small .button__label {
      margin-right: 4px; }
      .button--small .button__icon:last-child,
      .button--small .button__label:last-child {
        margin-right: 0px; }
    .button--small:hover:before {
      border-width: 1px; }
  .button--loading {
    pointer-events: none; }
    .button--loading .element-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      transform-style: preserve-3d;
      margin: 0;
      z-index: 1; }
    .button--loading .button__icon,
    .button--loading .button__label {
      opacity: 0; }
  .button--dropdown {
    min-width: 126px;
    padding: 0 36px 0 0; }
    .button--dropdown .button__inner-button {
      padding: 12px 4px 12px 16px; }
    .button--dropdown .react-select__menu {
      top: 100%; }
  .button--dropdown.button--small .button__inner-button {
    padding-top: 5px;
    padding-bottom: 5px; }
  .button__inner-button {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0; }
  .button__toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .button:not(.button--disabled):hover .button__toggle {
      background: rgba(61, 62, 66, 0.1); }
    .button__toggle-icon {
      display: block;
      width: 10px;
      height: 10px;
      margin-left: -2px;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
      .button--dropdown-open .button__toggle-icon {
        transform: rotate(180deg); }
      .button__toggle-icon svg {
        display: block;
        width: 10px;
        height: 10px;
        fill: #ffffff; }
        .button--secondary:not(.button--disabled) .button__toggle-icon svg,
        .button--tertiary:not(.button--disabled) .button__toggle-icon svg {
          fill: #ff3e43; }
  .button .react-select__control {
    display: none !important; }
  .button .react-select__option--is-focused {
    color: #aaaabd;
    background-color: transparent; }
  .button .react-select__option--is-selected {
    color: #848692;
    background-color: #eeeef3; }
  .button--action {
    display: inline-block;
    width: 48px;
    margin: 0 0 0 8px;
    padding: 12px 14px; }
