/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.groups-footer {
  position: fixed;
  display: block;
  bottom: 0;
  left: auto;
  right: 0;
  width: calc(100% - 72px);
  max-width: calc(100% + 72px);
  z-index: 10; }
  .sidebar--menu-opened + .main .groups-footer {
    width: calc(100% - 328px);
    max-width: calc(100% + 328px); }
    @media (max-width: 1030px) {
      .sidebar--menu-opened + .main .groups-footer {
        width: 100%;
        max-width: 100%; } }
  @media (max-width: 1030px) {
    .groups-footer {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1601px) {
    .groups-footer {
      top: 0;
      left: auto;
      width: 300px !important;
      max-width: 300px !important; } }
  @media (min-width: 1601px) {
    .groups-footer--closed {
      width: 24px !important; } }
  @media print {
    .groups-footer {
      display: none; } }
  .groups-footer__content {
    width: 100%;
    padding: 20px 16px;
    background: #fff;
    box-shadow: 0px -4px 10px -8px rgba(61, 62, 66, 0.75);
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .groups-footer--closed .groups-footer__content > *:not(.groups-footer__visibility) {
      display: none; }
    @media (max-width: 480px) {
      .groups-footer__content {
        padding: 8px; } }
    @media (min-width: 1031px) {
      .groups-footer__content {
        padding-left: 72px;
        padding-right: 72px; } }
    @media (min-width: 1601px) {
      .groups-footer__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        box-shadow: -4px 0px 10px -8px rgba(61, 62, 66, 0.75);
        padding: 16px;
        overflow: scroll; } }
  .groups-footer__filters {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 820px;
    margin: 0 auto 24px; }
    @media (max-width: 1030px) {
      .groups-footer__filters {
        margin-bottom: 16px; } }
    @media (min-width: 1031px) {
      .groups-footer__filters {
        min-width: 700px;
        max-width: 60%; } }
    @media (min-width: 1601px) {
      .groups-footer__filters {
        min-width: 100%;
        max-width: 100%; } }
    .groups-footer__filters-title {
      flex: 0 1 60px;
      margin: 0;
      padding: 0 8px 0 0;
      font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
      @media (max-width: 1030px) {
        .groups-footer__filters-title {
          padding-top: 8px; } }
      @media (min-width: 1031px) {
        .groups-footer__filters-title {
          position: absolute;
          margin-left: -54px; } }
    .groups-footer__filters-options {
      flex: 0 1 700px; }
      @media (min-width: 1031px) {
        .groups-footer__filters-options {
          flex: 0 0 100%; } }
      .groups-footer__filters--disabled .groups-footer__filters-options {
        pointer-events: none; }
      @media (max-width: 1030px) {
        .groups-footer__filters-options {
          overflow-x: auto;
          overflow-y: visible;
          padding-top: 8px; } }
      @media (max-width: 1030px) {
        .groups-footer__filters-options .form__columns {
          flex-wrap: nowrap; } }
      @media (min-width: 1601px) {
        .groups-footer__filters-options .form__columns {
          flex-wrap: wrap; } }
      .groups-footer__filters-options .form__columns .form__column:first-child {
        max-width: 200px; }
      .groups-footer__filters-options .form__columns .form__column:last-child {
        margin-right: 0; }
      @media (min-width: 1601px) {
        .groups-footer__filters-options .form__columns .form__column {
          flex-basis: 100% !important;
          width: 100% !important;
          max-width: 100% !important;
          margin: 0 0 16px !important; }
          .groups-footer__filters-options .form__columns .form__column:last-child {
            margin-bottom: 0 !important; } }
  .groups-footer__slider {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 820px;
    min-height: 38px;
    margin: 0 auto; }
    @media (min-width: 1031px) {
      .groups-footer__slider {
        min-width: 700px;
        max-width: 60%; } }
    @media (min-width: 1601px) {
      .groups-footer__slider {
        flex-wrap: wrap;
        min-width: 100%;
        max-width: 100%; } }
    .groups-footer__slider-track {
      flex: 0 1 700px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 38px; }
      .groups-footer__slider-track--is-dragging-over {
        box-shadow: 0px 0px 0px 2px rgba(119, 158, 224, 0.5); }
      @media (min-width: 1031px) {
        .groups-footer__slider-track {
          flex: 1 1 100%; } }
      @media (min-width: 1601px) {
        .groups-footer__slider-track {
          flex: 1 1 100%;
          flex-wrap: wrap; } }
      .groups-footer__slider-track .group-item {
        flex: 0 1 33%;
        margin: 0 24px 0 0; }
        @media (max-width: 480px) {
          .groups-footer__slider-track .group-item {
            margin-right: 4px; } }
        .groups-footer__slider-track .group-item:last-child {
          margin-right: 0; }
          @media (max-width: 480px) {
            .groups-footer__slider-track .group-item:last-child {
              margin-right: 0; } }
        @media (min-width: 1601px) {
          .groups-footer__slider-track .group-item {
            flex: 1 1 100%;
            margin: 0 0 8px; } }
    .groups-footer__slider-control {
      flex: 0 1 60px;
      display: flex;
      align-items: center; }
      @media (max-width: 480px) {
        .groups-footer__slider-control {
          flex: 0 0 35px; } }
      @media (min-width: 1031px) {
        .groups-footer__slider-control {
          position: absolute; } }
      .groups-footer__slider-control--prev {
        justify-content: flex-start; }
        @media (max-width: 480px) {
          .groups-footer__slider-control--prev {
            margin-left: -8px; } }
        @media (min-width: 1031px) {
          .groups-footer__slider-control--prev {
            margin-left: -60px; } }
      .groups-footer__slider-control--next {
        justify-content: flex-end; }
        @media (max-width: 480px) {
          .groups-footer__slider-control--next {
            margin-right: -8px; } }
        @media (min-width: 1031px) {
          .groups-footer__slider-control--next {
            right: -60px; } }
        @media (min-width: 1601px) {
          .groups-footer__slider-control--next {
            display: none; } }
      .groups-footer__slider-control-button {
        padding: 8px;
        width: 35px;
        height: 35px;
        background-color: #fff !important;
        border-radius: 100%; }
        @media (max-width: 480px) {
          .groups-footer__slider-control-button {
            width: 35px;
            height: 35px; } }
        .groups-footer__slider-control-button:active, .groups-footer__slider-control-button:focus, .groups-footer__slider-control-button:hover {
          background-color: #fff; }
        .groups-footer__slider-control-button .svg {
          transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
          .groups-footer__slider-control-button .svg svg {
            fill: #3d3e42 !important; }
        .groups-footer__slider-control-button:hover {
          background-color: #ff3e43 !important; }
          .groups-footer__slider-control-button:hover .svg svg {
            fill: #ffffff !important; }
        .groups-footer__slider-control-button.button--disabled {
          background-color: #fff !important; }
          .groups-footer__slider-control-button.button--disabled .svg svg {
            fill: #dadae4 !important; }
          .groups-footer__slider-control-button.button--disabled:hover {
            background-color: #fff !important; }
            .groups-footer__slider-control-button.button--disabled:hover .svg svg {
              fill: #dadae4 !important; }
  .groups-footer__visibility {
    position: absolute;
    top: -14px;
    right: 16px; }
    @media (max-width: 480px) {
      .groups-footer__visibility {
        top: -22px; } }
    @media (min-width: 1601px) {
      .groups-footer__visibility {
        top: 8px;
        left: -16px;
        width: 26px;
        transform: rotate(-90deg);
        transform-origin: center; } }
    .groups-footer__visibility-button {
      padding: 5px;
      background-color: #848692; }
      .groups-footer__visibility-button:active, .groups-footer__visibility-button:focus {
        background-color: #848692; }
      .groups-footer__visibility-button:hover {
        background-color: #777986; }
      .groups-footer__visibility-button .svg {
        position: relative;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
        .groups-footer--closed .groups-footer__visibility-button .svg {
          transform: rotateX(180deg); }
  .groups-footer__no-results {
    display: block;
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
    min-height: 38px;
    color: #848692; }
    .groups-footer__no-results-text {
      margin: 0 0 8px; }
  .groups-footer .react-select__value-container {
    max-height: 40px; }
  @media (max-width: 1600px) {
    .groups-footer .react-select__menu {
      top: auto;
      bottom: calc(100% + 8px); } }
