/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.resellers-table-reseller-info {
  position: relative;
  display: flex;
  flex-direction: column; }
  .resellers-table-reseller-info__name {
    text-transform: capitalize; }
  .resellers-table-reseller-info__phone {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692; }
  .resellers-table-reseller-info__email {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692; }
