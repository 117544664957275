/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.accordion-item--opened .accordion-item__icon {
  transform: rotate(180deg); }

.accordion-item--opened .accordion-item__line {
  border-radius: 3px 3px 0 0; }

.accordion-item--opened .accordion-item__inner {
  max-height: 100rem;
  transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition-duration: 0.5s;
  transition-property: max-height; }

.accordion-item--opened .accordion-item__content {
  opacity: 1;
  transform: translateY(0);
  transition-delay: 0.2s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  transition-property: opacity, transform; }

.accordion-item--border {
  border: 1px solid #dadae4;
  border-radius: 3px; }
  .accordion-item--border .accordion-item__line {
    background: none;
    border: none; }
  .accordion-item--border .accordion-item__inner {
    border: none; }
  .accordion-item--border .accordion-item__content {
    background: none;
    border: none; }

.accordion-item__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 3px;
  text-transform: inherit;
  text-align: inherit;
  text-decoration: none;
  color: inherit;
  background-color: #fafafb;
  background-image: none;
  vertical-align: inherit;
  -webkit-user-select: inherit;
     -moz-user-select: inherit;
      -ms-user-select: inherit;
          user-select: inherit;
  touch-action: inherit;
  cursor: pointer;
  white-space: inherit; }

.accordion-item__title {
  font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }

.accordion-item__icon {
  height: 8px;
  width: 8px;
  transition: transform 0.3s ease-in-out;
  background-size: contain;
  background-repeat: no-repeat; }

.accordion-item__inner {
  max-height: 0;
  overflow: hidden;
  transition-duration: 0.5s;
  transition-property: max-height;
  border-radius: 3px; }

.accordion-item__content {
  opacity: 0;
  transform: translateY(-1rem);
  transition-timing-function: linear, ease;
  transition-duration: 0.1s;
  transition-property: opacity, transform;
  transition-delay: 0.5s;
  padding: 0 16px 16px;
  background-color: #fafafb;
  border-radius: 0 0 3px 3px; }
  .accordion-item__content .gallery:last-child {
    margin-bottom: -16px; }
