/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.widget {
  position: relative;
  flex: 1 1;
  width: 100%;
  max-width: 100%;
  min-height: 1px;
  overflow: hidden;
  margin: 0 16px 16px 0;
  background: #ffffff;
  box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
  border-radius: 3px;
  padding: 16px;
  display: flex;
  flex-direction: column; }
  .widget:last-child {
    margin-right: 0; }
  .widget__header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 16px; }
    .widget__header:last-child {
      margin-bottom: 0; }
    .widget__header-main {
      flex: 1 1; }
    .widget__header-aside {
      flex: 1 1;
      text-align: right;
      position: relative;
      padding-left: 16px; }
  .widget__title {
    position: relative;
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0; }
  .widget__subtitle {
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    color: #aaaabd;
    margin: 0; }
  .widget__filters {
    display: flex;
    padding-top: 4px; }
    .widget__filters .input {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center; }
      .widget__filters .input__label {
        background-color: transparent;
        position: relative;
        margin-right: 8px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
        color: #3d3e42; }
      .widget__filters .input--active .input__label {
        transform: translate(0px, 0px); }
    .widget__filters .react-select__value-container {
      display: flex;
      height: 100%;
      align-items: center; }
    .widget__filters .react-select__control {
      height: 40px;
      display: block;
      font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
      padding: 0 8px 0 0;
      border-radius: 3px;
      position: relative;
      color: #3d3e42;
      background-color: #ffffff;
      border: none; }
    .widget__filters .react-select__single-value {
      display: block;
      padding: 0px 32px 0 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .widget__content {
    position: relative;
    display: flex;
    flex-grow: 1;
    margin: 0;
    z-index: 0; }
  .widget--1 {
    flex-basis: calc(8.333333% - 16px + (16px / 12));
    max-width: calc(8.333333% - 16px + (16px / 12)); }
    .widget--1:nth-of-type(12n) {
      margin-right: 0; }
  .widget--2 {
    flex-basis: calc(16.666667% - 16px + (16px / 6));
    max-width: calc(16.666667% - 16px + (16px / 6)); }
    .widget--2:nth-of-type(6n) {
      margin-right: 0; }
    @media (max-width: 1300px) {
      .widget--2 {
        flex-basis: calc(50% - 16px + (16px / 4));
        max-width: calc(50% - 16px + (16px / 4)); }
        .widget--2:nth-of-type(2n) {
          margin-right: 0; } }
    @media (max-width: 600px) {
      .widget--2 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--3 {
    flex-basis: calc(25% - 16px + (16px / 4));
    max-width: calc(25% - 16px + (16px / 4)); }
    .widget--3:nth-of-type(4n) {
      margin-right: 0; }
    @media (max-width: 1470px) {
      .widget--3 {
        flex-basis: calc(50% - 16px + (16px / 4));
        max-width: calc(50% - 16px + (16px / 4)); }
        .widget--3:nth-of-type(2n) {
          margin-right: 0; } }
    @media (max-width: 600px) {
      .widget--3 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--4 {
    flex-basis: calc(33.333333% - 16px + (16px / 3));
    max-width: calc(33.333333% - 16px + (16px / 3)); }
    .widget--4:nth-of-type(3n) {
      margin-right: 0; }
    @media (max-width: 1030px) {
      .widget--4 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--5 {
    flex-basis: 41.666667%;
    max-width: 41.666667%; }
  .widget--6 {
    flex-basis: calc(50% - 16px + (16px / 2));
    max-width: calc(50% - 16px + (16px / 2)); }
    .widget--6:nth-of-type(2n) {
      margin-right: 0; }
    @media (max-width: 1030px) {
      .widget--6 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--7 {
    flex-basis: 58.333333%;
    max-width: 58.333333%; }
    @media (max-width: 1030px) {
      .widget--7 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--8 {
    flex-basis: 66.666667%;
    max-width: 66.666667%; }
    @media (max-width: 1030px) {
      .widget--8 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--9 {
    flex-basis: 75%;
    max-width: 75%; }
    @media (max-width: 1030px) {
      .widget--9 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--10 {
    flex-basis: 83.333333%;
    max-width: 83.333333%; }
    @media (max-width: 1030px) {
      .widget--10 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--11 {
    flex-basis: 91.666667%;
    max-width: 91.666667%; }
    @media (max-width: 1030px) {
      .widget--11 {
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0; } }
  .widget--12 {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0px; }
  .widget .chartjs-render-monitor {
    width: 100% !important; }
