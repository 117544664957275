/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.card {
  display: flex;
  flex-direction: column;
  background: #fafafb;
  padding: 16px;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0 rgba(61, 62, 66, 0.1); }
  .card__break {
    height: 1px;
    background-color: rgba(132, 134, 146, 0.15);
    margin: 16px 0; }
  .card__header {
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif; }
