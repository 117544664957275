/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.page-content {
  width: 100%;
  display: block;
  padding: 0 0 24px 0; }
  .page-content__column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border: none;
    padding: 0px;
    margin: 0px; }
  .page-content__columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px; }
    .page-content__columns:last-child {
      margin-bottom: 0px; }
    .page-content__columns .page-content__column {
      flex-basis: 50%;
      width: 50%;
      margin-right: 16px; }
      .page-content__columns .page-content__column:last-child {
        margin-right: 0px; }
  .page-content__row {
    display: flex;
    flex-wrap: wrap; }
