/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.main {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 72px; }
  @media (max-width: 1030px) {
    .main {
      padding-top: 56px;
      padding-left: 24px;
      padding-right: 24px; } }
  .main--narrow > * {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; }
  @media (min-width: 1601px) {
    .main--table-allotments {
      margin-right: 300px; } }
  .main--table-allotments > * {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 1031px) {
      .main--table-allotments > * {
        min-width: 700px;
        max-width: 60%; } }
