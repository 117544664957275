/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.location-pin {
  flex: 1 1;
  display: block;
  width: 100%;
  position: relative;
  margin: 0 16px 0 0; }
  .location-pin--disabled {
    pointer-events: none;
    opacity: 0.8; }
  .location-pin .gm-style > div {
    cursor: pointer !important; }
  .location-pin:last-child {
    margin-right: 0px; }
  .location-pin__loading {
    height: 100%; }
  .location-pin__container {
    height: 135px; }
  .location-pin__map {
    height: 100%; }
