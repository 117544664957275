/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

@media (max-width: 480px) {
  .event-sales table {
    width: 700px; } }

.event-sales .listing {
  margin-bottom: 0; }
  .event-sales .listing__body {
    overflow: hidden; }
    @media (max-width: 480px) {
      .event-sales .listing__body {
        overflow: auto; } }
  @media (min-width: 1031px) {
    .event-sales .listing .table__header th:not(:first-of-type) {
      text-align: right; } }
