/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.gauge-chart {
  display: block;
  height: auto;
  width: 100%;
  position: relative; }
  .gauge-chart canvas {
    z-index: 100;
    position: relative; }
