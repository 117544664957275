/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.showing-results {
  display: flex;
  font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  color: #aaaabd; }
  @media (max-width: 1030px) {
    .showing-results {
      justify-content: center; } }
  .showing-results__per-page {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row; }
    .showing-results__per-page-button {
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
      color: #848692;
      padding: 0;
      position: relative;
      min-width: 24px;
      margin-left: 8px;
      background: transparent;
      border: none;
      z-index: 2;
      line-height: 24px;
      text-align: center;
      cursor: pointer;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
      .showing-results__per-page-button:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        opacity: 1;
        min-width: 24px;
        height: 24px;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
        border-radius: 12px;
        background-color: #eeeef3;
        z-index: -1; }
      .showing-results__per-page-button--arrow {
        margin: 0 4px; }
      .showing-results__per-page-button--active {
        font-weight: bold;
        color: #3d3e42; }
      .showing-results__per-page-button--disabled {
        pointer-events: none; }
      .showing-results__per-page-button:hover:after {
        opacity: 0.5; }
    .showing-results__per-page-text {
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
      margin-left: 8px;
      color: #848692; }
