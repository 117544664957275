/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.table {
  width: 100%;
  font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
  padding-bottom: 16px;
  color: #3d3e42;
  border-top: 1px solid #eeeef3;
  border-bottom: 1px solid #eeeef3; }
  .table__inner {
    display: block;
    margin: 0 -8px; }
    @media (max-width: 1030px) {
      .table__inner {
        margin: 0; } }
  .table__list {
    width: 100%;
    text-align: left; }
  .table__header {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .table__header th {
      padding: 24px 8px; }
      .table__header th:last-child {
        text-align: right; }
  .table__head--right .table__sortable {
    padding-right: 0 !important;
    justify-content: flex-end; }
  .table__body tr {
    box-shadow: 0 0 0 0 #aaaabd;
    transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out; }
    .table__body tr:hover {
      background-color: #ffffff;
      box-shadow: 0 0 4px -2px #aaaabd; }
      .table__body tr:hover .table-actions__items {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        padding-right: 8px; }
      .table__body tr:hover .table-actions__dots {
        opacity: 0;
        visibility: hidden; }
  .table__body td {
    padding: 8px; }
  .table__body-data--center {
    text-align: center; }
  .table__body-data--right {
    text-align: right; }
  .table__sortable {
    position: relative;
    display: flex;
    width: 100%;
    border: none;
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    text-align: left;
    padding-left: 0;
    cursor: pointer;
    white-space: nowrap;
    background: transparent;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    padding-right: 16px;
    color: #3d3e42; }
    .table__sortable:hover .table__sortable-icon {
      opacity: 1;
      -webkit-animation: bounce 1s infinite;
              animation: bounce 1s infinite; }
  .table__sortable-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8px;
    height: 18px;
    margin-left: 8px;
    transform: rotate(180deg);
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    color: #3d3e42;
    opacity: 0; }
    .table__sortable-icon svg {
      display: block;
      width: 8px;
      height: 8px;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      fill: #3d3e42; }
  .table__sortable--asc .table__sortable-icon {
    opacity: 1;
    transform: rotate(180deg);
    -webkit-animation: none;
            animation: none; }
  .table__sortable--asc:hover .table__sortable-icon {
    -webkit-animation: none;
            animation: none; }
  .table__sortable--desc .table__sortable-icon {
    opacity: 1;
    transform: rotate(0deg);
    -webkit-animation: none;
            animation: none; }
  .table__sortable--desc:hover .table__sortable-icon {
    -webkit-animation: none;
            animation: none; }
  .table__body-data--subcategory {
    margin-left: 16px;
    margin-right: 8px; }

.table-mobile {
  display: block;
  color: #3d3e42; }
  .table-mobile__additional {
    display: none;
    padding-top: 16px; }
  .table-mobile__btn {
    left: 0;
    border: 0;
    z-index: 2;
    padding: 0;
    font-size: 0;
    width: 100vw;
    line-height: 0;
    display: block;
    cursor: pointer;
    position: absolute;
    top: -16px;
    bottom: -16px;
    -webkit-appearance: none;
    margin: 0 0 0 -24px;
    background-color: transparent; }
  .table-mobile__list {
    display: block;
    margin-left: -24px;
    margin-right: -24px; }
  .table-mobile__list-item {
    padding: 16px 24px;
    border-top: 1px solid #eeeef3; }
    .table-mobile__list-item:last-child {
      border-bottom: 1px solid #eeeef3; }
  .table-mobile__list-header {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    align-content: center;
    justify-content: flex-end; }
  .table-mobile__list-checkbox {
    z-index: 3;
    position: relative;
    margin-right: 16px; }
    .table-mobile__list-checkbox .input-checkbox,
    .table-mobile__list-checkbox .input-checkbox__icon {
      margin: 0; }
  .table-mobile__list-title {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    display: block;
    margin-right: auto; }
  .table-mobile__list-status {
    display: block;
    padding-left: 16px; }
  .table-mobile__list-arrow {
    display: block;
    width: 32px;
    padding-left: 16px; }
    .table-mobile__list-arrow .svg,
    .table-mobile__list-arrow svg {
      display: block;
      width: 16px;
      height: 16px;
      fill: #3d3e42; }
  .table-mobile__list-subtitle {
    margin: 0;
    width: 100%;
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    list-style-type: none;
    padding: 16px 0 0; }
    .table-mobile__list-subtitle--has-bulk-actions {
      padding: 16px 0 0 40px; }
  .table-mobile__list-subtitle-item {
    display: block;
    padding-bottom: 4px; }
    .table-mobile__list-subtitle-item:last-child {
      padding-bottom: 0; }
  .table-mobile__table {
    width: 100%;
    display: table;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    table-layout: fixed;
    word-break: break-word; }
  .table-mobile__table-row td .status-badge:first-child {
    margin-left: 0; }
  .table-mobile__table-col-left {
    font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    width: 120px;
    vertical-align: top;
    padding: 4px 16px 0 0; }
  .table-mobile__table-col-right {
    vertical-align: top;
    padding: 4px 0 0 0; }
  .table-mobile__actions {
    display: block;
    padding-top: 16px; }
    .table-mobile__actions .table-actions {
      float: none;
      width: auto;
      height: auto;
      cursor: default; }
      .table-mobile__actions .table-actions__dots {
        display: none; }
      .table-mobile__actions .table-actions__items {
        top: auto;
        margin: 0;
        opacity: 1;
        padding: 0;
        right: auto;
        width: 100%;
        bottom: auto;
        display: flex;
        position: relative;
        visibility: visible;
        flex-direction: row;
        pointer-events: all;
        justify-content: flex-start; }
      .table-mobile__actions .table-actions__item {
        padding-left: 0;
        padding-right: 8px; }
        .table-mobile__actions .table-actions__item:empty {
          display: none; }
        .table-mobile__actions .table-actions__item:last-child {
          padding-right: 0; }
        .table-mobile__actions .table-actions__item:last-child:first-child {
          padding-right: 8px; }
        .table-mobile__actions .table-actions__item a,
        .table-mobile__actions .table-actions__item button {
          width: auto;
          height: auto;
          cursor: pointer;
          padding: 8px;
          border-radius: 3px;
          -webkit-appearance: none;
          border: 1px solid #aaaabd; }
        .table-mobile__actions .table-actions__item svg,
        .table-mobile__actions .table-actions__item .svg {
          display: block; }
  .table-mobile__sorting {
    display: inline-block;
    position: relative;
    margin-bottom: 16px; }
  .table-mobile__bulk {
    display: inline-block;
    margin-right: 16px; }
  .table-mobile__sorting-btn {
    margin: 0;
    padding: 0;
    width: auto;
    border: none;
    display: flex;
    cursor: pointer;
    border-radius: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    -webkit-appearance: none;
    justify-content: flex-start;
    background-color: transparent; }
  .table-mobile__sorting-dropdown {
    top: 100%;
    z-index: 10;
    width: 300px;
    display: none;
    border-radius: 3px;
    position: absolute;
    left: -16px;
    margin-top: 16px;
    background-color: #ffffff;
    padding: 16px 24px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }
    @media (max-width: 480px) {
      .table-mobile__sorting-dropdown {
        width: 100vw;
        left: -24px; } }
    .table-mobile__sorting-dropdown:before {
      top: -5px;
      left: 17px;
      z-index: 1;
      width: 14px;
      height: 14px;
      content: '\A';
      position: absolute;
      pointer-events: none;
      transform: rotate(45deg);
      background-color: #ffffff;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }
      @media (max-width: 480px) {
        .table-mobile__sorting-dropdown:before {
          left: 27px; } }
    .table-mobile__sorting-dropdown:after {
      left: 0;
      top: 2px;
      right: 2px;
      z-index: 2;
      content: '\A';
      position: absolute;
      pointer-events: none;
      height: 16px;
      background-color: #ffffff; }
  .table-mobile__sorting-icon {
    width: 16px;
    display: block;
    margin-right: 8px; }
    .table-mobile__sorting-icon svg {
      width: 100%;
      display: block;
      fill: #aaaabd; }
  .table-mobile__sorting-label {
    font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    display: block;
    color: #aaaabd; }
    .table-mobile__sorting-label strong {
      color: #3d3e42; }
  .table-mobile__sorting-item {
    display: block;
    padding: 4px 0; }
  .table-mobile__list-item--active .table-mobile__additional {
    display: block; }
    .table-mobile__list-item--active .table-mobile__additional--has-bulk-actions {
      padding: 16px 0 0 40px; }
  .table-mobile__list-item--active .table-mobile__list-arrow svg {
    transform: rotate(90deg); }
  .table-mobile__sorting--active .table-mobile__sorting-dropdown {
    display: block; }
  .table-mobile__sorting--ASC .table-mobile__sorting-icon svg path:first-child {
    fill: #3d3e42; }
  .table-mobile__sorting--DESC .table-mobile__sorting-icon svg path:last-child {
    fill: #3d3e42; }

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    margin-top: 0; }
  40% {
    margin-top: -4px; }
  60% {
    margin-top: -2px; } }

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    margin-top: 0; }
  40% {
    margin-top: -4px; }
  60% {
    margin-top: -2px; } }
