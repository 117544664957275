/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.input-radio {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
  margin-right: 16px; }
  .input-radio:last-child {
    margin-right: 0; }
  .input-radio__error {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    margin-left: 16px;
    margin-top: 8px;
    color: #fb6f75; }
  .input-radio__label {
    display: block;
    width: 100%;
    cursor: pointer;
    font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    padding-left: 26px;
    position: relative;
    color: #3d3e42; }
    .input-radio__label:before {
      left: 0;
      top: -2px;
      z-index: 1;
      width: 16px;
      height: 16px;
      content: '\A';
      position: absolute;
      border-radius: 100%;
      background-color: #ffffff;
      border: solid 2px #eeeef3; }
    .input-radio__label:after {
      top: 4px;
      left: 5px;
      z-index: 2;
      width: 10px;
      height: 10px;
      content: '\A';
      position: absolute;
      border-radius: 100%;
      transform: scale(0);
      transform-origin: center;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      background-color: #848692; }
  .input-radio input {
    display: none; }
    .input-radio input:checked + .input-radio__label:after {
      transform: scale(1); }
    .input-radio input:disabled + .input-radio__label {
      pointer-events: none;
      opacity: 0.6; }
      .input-radio input:disabled + .input-radio__label:before {
        background-color: #dadae4; }
