/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.main-navigation {
  width: 100%;
  padding: 0 8px 24px 8px; }
  .main-navigation__menu {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .main-navigation__menu-item {
      display: block;
      width: 100%;
      margin: 0;
      padding: 0; }
      .main-navigation__menu-item--open .main-navigation__submenu {
        height: auto; }
      .main-navigation__menu-item--open .main-navigation__menu-link-badge {
        transform: rotate(180deg); }
    .main-navigation__menu-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      width: 100%;
      text-align: left;
      color: #3d3e42;
      margin: 0;
      padding: 8px 16px;
      border: none;
      border-radius: 3px;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer; }
      .main-navigation__menu-link:hover {
        color: #ff3e43;
        text-decoration: none; }
      .main-navigation__menu-link-badge {
        font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
        color: #aaaabd;
        background-color: transparent;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
        .main-navigation__menu-link-badge .svg {
          display: block;
          width: 8px;
          height: 8px;
          fill: #3d3e42; }
      .main-navigation__menu-link--active {
        color: #de102d;
        background-color: #eeeef3; }
  .main-navigation__submenu {
    height: 0px;
    list-style-type: none;
    padding: 0px;
    overflow: hidden; }
    .main-navigation__submenu-item {
      padding: 0; }
    .main-navigation__submenu-link {
      display: block;
      padding: 8px 16px 8px 32px;
      color: #3d3e42;
      text-decoration: none;
      cursor: pointer; }
      .main-navigation__submenu-link:hover {
        text-decoration: none; }
      .main-navigation__submenu-link--active {
        background-color: #eeeef3; }
