/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  z-index: 9999;
  margin-left: -16px;
  position: absolute; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 16px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 16px;
    left: -16px;
    border-bottom-color: white; }

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 0;
  margin-top: -16px; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle, .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-top: none; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: white; }

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -16px; }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: white; }

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -16px;
  position: absolute; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 16px solid transparent;
    height: 0;
    width: 1px; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    content: '';
    z-index: -1;
    border-width: 16px;
    left: -16px;
    border-bottom-color: white; }

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 0;
  margin-top: -16px; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle, .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-top: none; }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: white; }

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -16px; }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff; }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: white; }

.react-datepicker-wrapper {
  display: inline-block;
  width: 100%; }

.react-datepicker {
  font-size: 13px;
  background-color: #fff;
  color: #3d3e42;
  border: 1px solid white;
  display: flex;
  flex-wrap: nowrap;
  position: relative; }

.react-datepicker--time-only {
  padding-left: 0; }
  .react-datepicker--time-only .react-datepicker__triangle {
    left: 35px; }
  .react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0; }
  .react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem; }
  .react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem; }

.react-datepicker__triangle {
  position: absolute;
  left: 50px; }

.react-datepicker-popper {
  z-index: 999;
  background: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1); }
  .react-datepicker-popper[data-placement^='bottom'] {
    margin-top: 18px; }
  .react-datepicker-popper[data-placement^='top'] {
    margin-bottom: 18px; }
  .react-datepicker-popper[data-placement^='right'] {
    margin-left: 16px; }
    .react-datepicker-popper[data-placement^='right'] .react-datepicker__triangle {
      left: auto;
      right: 42px; }
  .react-datepicker-popper[data-placement^='left'] {
    margin-right: 16px; }
    .react-datepicker-popper[data-placement^='left'] .react-datepicker__triangle {
      left: 42px;
      right: auto; }

.react-datepicker__header {
  text-align: center;
  border-bottom: 1px solid white;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: relative; }

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block; }

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  margin-bottom: 8px;
  color: #3d3e42;
  font: 700 16px/20px "Roboto", Helvetica, Arial, sans-serif;
  font-size: 15.34px; }

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-indent: -15px; }

.react-datepicker__navigation {
  background: none;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 24px;
  width: 0;
  padding: 0;
  border: 8px solid transparent;
  z-index: 1;
  text-indent: -999em; }
  .react-datepicker__navigation--previous {
    left: 36px; }
    .react-datepicker__navigation--previous:after {
      transform: rotate(-45deg);
      left: 4px; }
    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
      cursor: default;
      opacity: 0.2; }
  .react-datepicker__navigation--next {
    right: 36px; }
    .react-datepicker__navigation--next:after {
      transform: rotate(135deg);
      left: 2px; }
    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
      right: 132px; }
    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
      cursor: default;
      opacity: 0.2; }
  .react-datepicker__navigation--previous, .react-datepicker__navigation--next {
    width: 32px;
    height: 32px;
    background-color: #eeeef3;
    border-radius: 50%; }
    .react-datepicker__navigation--previous:after, .react-datepicker__navigation--next:after {
      position: absolute;
      content: '';
      display: block;
      top: 3px;
      width: 7px;
      height: 7px;
      border-left: 2px solid #3d3e42;
      border-top: 2px solid #3d3e42; }
  .react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto; }
    .react-datepicker__navigation--years-previous {
      top: 4px;
      border-top-color: #eeeef3; }
      .react-datepicker__navigation--years-previous:hover {
        border-top-color: #d0d0de; }
    .react-datepicker__navigation--years-upcoming {
      top: -4px;
      border-bottom-color: #eeeef3; }
      .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #d0d0de; }

.react-datepicker__month-container {
  margin: 32px; }

.react-datepicker__month {
  margin-top: 16px;
  text-align: center; }
  .react-datepicker__month .react-datepicker__month-text {
    display: inline-block;
    width: 4rem;
    margin: 2px; }

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left; }
  .react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block; }
  .react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
      display: inline-block;
      margin-left: 10px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
        width: 85px; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type='time']::-webkit-inner-spin-button,
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type='time']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0; }
      .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type='time'] {
        -moz-appearance: textfield; }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
      margin-left: 5px;
      display: inline-block; }

.react-datepicker__time-container {
  padding: 32px 0;
  border-left: 1px solid white;
  width: 100px; }
  .react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0; }
  .react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white; }
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      width: 100px;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
      .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
        list-style: none;
        margin: 0;
        height: calc(195px + (32px / 2));
        overflow-y: scroll;
        padding-right: 0px;
        padding-left: 0px;
        width: 100%;
        box-sizing: content-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
          width: 15px; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-track {
          background: #fff; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: #aaaabd;
          border: 3px solid rgba(0, 0, 0, 0);
          background-clip: padding-box; }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
          height: 30px;
          line-height: 32px;
          border-radius: 3px;
          margin: 6px 0; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            cursor: pointer;
            background-color: #eeeef3; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: #de102d;
            color: white;
            font-weight: bold; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
              background-color: #de102d; }
          .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
            color: #eeeef3; }
            .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
              cursor: default;
              background-color: transparent; }

.react-datepicker__week-number {
  color: #eeeef3;
  display: inline-block;
  width: 32px;
  line-height: 32px;
  text-align: center;
  margin: 2px; }
  .react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer; }
    .react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
      border-radius: 50%; }

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap; }
  .react-datepicker__day-names .react-datepicker__day-name,
  .react-datepicker__week .react-datepicker__day-name {
    color: #aaaabd;
    font-weight: bold; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #3d3e42;
  display: inline-block;
  width: 32px;
  line-height: 32px;
  text-align: center;
  margin: 2px;
  cursor: pointer; }

.react-datepicker__day:hover {
  background: #eeeef3; }

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  border-radius: 50%;
  background-color: #de102d;
  color: #fff; }
  .react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
    background-color: #c60e28; }

.react-datepicker__month--disabled {
  color: #eeeef3;
  pointer-events: none; }
  .react-datepicker__month--disabled:hover {
    cursor: default;
    background-color: transparent; }

.react-datepicker__day,
.react-datepicker__month-text {
  cursor: pointer; }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    border-radius: 50%; }
  .react-datepicker__day--today,
  .react-datepicker__month-text--today {
    font-weight: bold; }
  .react-datepicker__day--highlighted,
  .react-datepicker__month-text--highlighted {
    border-radius: 50%;
    background-color: #eeeef3;
    color: #fff; }
    .react-datepicker__day--highlighted:hover,
    .react-datepicker__month-text--highlighted:hover {
      background-color: #dfdfe8; }
    .react-datepicker__day--highlighted-custom-1,
    .react-datepicker__month-text--highlighted-custom-1 {
      color: magenta; }
    .react-datepicker__day--highlighted-custom-2,
    .react-datepicker__month-text--highlighted-custom-2 {
      color: green; }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range {
    border-radius: 50%;
    background-color: #de102d;
    color: #fff; }
    .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
    .react-datepicker__month-text--selected:hover,
    .react-datepicker__month-text--in-selecting-range:hover,
    .react-datepicker__month-text--in-range:hover {
      background-color: #c60e28; }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    border-radius: 50%;
    background-color: #f0314c;
    color: #fff; }
    .react-datepicker__day--keyboard-selected:hover,
    .react-datepicker__month-text--keyboard-selected:hover {
      background-color: #c60e28; }
  .react-datepicker__day--in-selecting-range ,
  .react-datepicker__month-text--in-selecting-range {
    background-color: rgba(222, 16, 45, 0.5); }
  .react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
  .react-datepicker__month-text--in-range {
    color: #3d3e42; }
  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled {
    cursor: default;
    color: #eeeef3; }
    .react-datepicker__day--disabled:hover,
    .react-datepicker__month-text--disabled:hover {
      background-color: transparent; }

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #de102d; }

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%; }

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 50%; }
  .react-datepicker__year-read-view:hover,
  .react-datepicker__month-read-view:hover,
  .react-datepicker__month-year-read-view:hover {
    cursor: pointer; }
    .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
      border-top-color: #d0d0de; }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #eeeef3;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 8px; }

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 50%;
  border: 1px solid white; }
  .react-datepicker__year-dropdown:hover,
  .react-datepicker__month-dropdown:hover,
  .react-datepicker__month-year-dropdown:hover {
    cursor: pointer; }
  .react-datepicker__year-dropdown--scrollable,
  .react-datepicker__month-dropdown--scrollable,
  .react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll; }

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .react-datepicker__year-option:first-of-type,
  .react-datepicker__month-option:first-of-type,
  .react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%; }
  .react-datepicker__year-option:last-of-type,
  .react-datepicker__month-option:last-of-type,
  .react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%; }
  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #eeeef3; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
      border-bottom-color: #d0d0de; }
    .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
    .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
      border-top-color: #d0d0de; }
  .react-datepicker__year-option--selected,
  .react-datepicker__month-option--selected,
  .react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px; }

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px; }
  .react-datepicker__close-icon::after {
    background-color: #de102d;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: '\00d7';
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center; }

.react-datepicker__today-button {
  border-top: 1px solid white;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left; }

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647; }
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem; }
  @media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
      width: 2rem;
      line-height: 2rem; } }
  .react-datepicker__portal .react-datepicker__current-month,
  .react-datepicker__portal .react-datepicker-time__header {
    font-size: 23.4px; }
  .react-datepicker__portal .react-datepicker__navigation {
    border: 14.4px solid transparent; }
  .react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #eeeef3; }
    .react-datepicker__portal .react-datepicker__navigation--previous:hover {
      border-right-color: #d0d0de; }
    .react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
      border-right-color: white;
      cursor: default; }
  .react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #eeeef3; }
    .react-datepicker__portal .react-datepicker__navigation--next:hover {
      border-left-color: #d0d0de; }
    .react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
      border-left-color: white;
      cursor: default; }
