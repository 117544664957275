/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.profile-photo__preview {
  display: block;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 2px dashed #eeeef3;
  background-color: transparent;
  padding: 0px;
  margin: 0px auto 8px auto;
  overflow: hidden; }
  .profile-photo__preview img,
  .profile-photo__preview .svg {
    display: block;
    width: 100%;
    height: auto;
    fill: #dadae4; }

.profile-photo__drop-area {
  display: flex;
  align-items: center;
  padding: 0 8px;
  position: relative;
  text-align: center;
  height: 240px;
  border-radius: 3px;
  border: 2px dashed #eeeef3;
  text-align: center;
  padding: 8px;
  font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
  color: #848692; }
  .profile-photo__drop-area--active {
    border: 2px solid #de102d; }
  .profile-photo__drop-area span {
    margin: 0px auto; }
  .profile-photo__drop-area-button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0; }
  .profile-photo__drop-area input {
    display: none; }

.profile-photo__actions {
  margin-top: 8px;
  text-align: center; }
  .profile-photo__actions .button--small {
    margin: 0; }
    @media (min-width: 481px) {
      .profile-photo__actions .button--small {
        margin: 0 8px 0 0; } }
