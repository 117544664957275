/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.form--permissions-toggle {
  position: relative;
  display: flex;
  min-width: 100px; }
  .form--permissions-toggle .input-switch {
    margin: 0 auto; }
    @media (max-width: 1030px) {
      .form--permissions-toggle .input-switch {
        margin: 0 0 8px 0; } }
  .form--permissions-toggle .element-loader {
    background-color: rgba(255, 255, 255, 0.8); }
