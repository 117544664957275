/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.chart__pie-container {
  display: flex;
  align-items: center;
  height: 60px;
  width: 60px; }

.chart__legend {
  font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
  color: #3d3e42;
  padding-left: 16px; }

.chart-tooltip {
  display: flex;
  flex-direction: column; }
  .chart-tooltip__title {
    margin: 8px 8px -2px; }
  .chart-tooltip__body {
    display: flex;
    flex-direction: column;
    margin: 8px; }
  .chart-tooltip__data-container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .chart-tooltip__label {
    display: flex;
    margin-bottom: 0;
    flex: 45% 1;
    align-items: flex-start;
    align-self: auto; }
    .chart-tooltip__label span {
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .chart-tooltip__data {
    display: flex;
    flex: auto;
    margin-bottom: 0;
    margin-left: 24px;
    justify-content: flex-end; }
