/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.page-content--notifications .container {
  margin: 16px 0; }

.page-content--notifications .input-switch {
  margin: 0; }
