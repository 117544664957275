/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.listing {
  position: relative;
  width: 100%;
  display: block;
  margin-bottom: 40px; }
  .listing__header {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    margin-bottom: 16px;
    justify-content: space-between; }
    .listing__header:last-child {
      margin-bottom: -24px; }
      @media (max-width: 850px) {
        .listing__header:last-child {
          margin-bottom: -40px; } }
    @media (max-width: 1030px) {
      .listing__header {
        margin-bottom: 0;
        flex-wrap: wrap; } }
    @media (max-width: 1030px) {
      .listing__header > .listing__header-col {
        margin-bottom: 0;
        padding-bottom: 16px; } }
  .listing__header-col {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding-right: 24px;
    min-height: 32px; }
    .listing__header-col:empty {
      display: none; }
    @media (max-width: 1030px) {
      .listing__header-col {
        padding-right: 8px; } }
    .listing__header-col-child {
      display: inline-block;
      margin-right: 8px; }
      .listing__header-col-child:last-child {
        margin-right: 0; }
    .listing__header-col--aside {
      flex: 0 0 auto;
      padding-right: 0; }
    .listing__header-col--search {
      flex: 1 1 auto;
      padding-right: 0; }
    @media (max-width: 1030px) {
      .listing__header-col--search-active {
        flex: 1 1 100%;
        padding-right: 0; } }
    @media (max-width: 1030px) {
      .listing__header-col--search-active .search__input {
        width: 100%;
        padding-left: 48px; } }
    @media (max-width: 1030px) {
      .listing__header-col--search-active + .listing__header-col--aside {
        margin-left: 0; } }
  .listing__footer {
    width: 100%;
    display: flex;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    margin-top: 24px;
    color: #de102d;
    justify-content: space-between; }
    .listing__footer:empty {
      display: none; }
    @media (max-width: 1030px) {
      .listing__footer {
        display: block; } }
  .listing.listing--no-hover .table__header th,
  .listing.listing--no-hover .table__body td {
    padding-left: 0;
    padding-right: 8px; }
    .listing.listing--no-hover .table__header th:last-child,
    .listing.listing--no-hover .table__body td:last-child {
      padding-right: 0; }
  .listing.listing--no-hover .table__body tr:hover {
    box-shadow: none; }
    @media (max-width: 1030px) {
      .listing.listing--no-hover .table__body tr:hover {
        width: 100%;
        flex-direction: column; } }
  .listing.listing--zebra .table {
    border-top: none;
    border-bottom: none; }
    .listing.listing--zebra .table__list {
      background-color: #ffffff;
      border: 1px solid #eeeef3; }
    .listing.listing--zebra .table__header {
      border-bottom: 1px solid #eeeef3; }
      .listing.listing--zebra .table__header th {
        padding: 24px 16px; }
    .listing.listing--zebra .table__body td {
      padding: 8px 16px; }
    .listing.listing--zebra .table__body tr:nth-child(odd) {
      background-color: rgba(238, 238, 243, 0.6); }
    .listing.listing--zebra .table__body tr:hover {
      box-shadow: none; }
    .listing.listing--zebra .table__header th,
    .listing.listing--zebra .table__header td, .listing.listing--zebra .table__body th,
    .listing.listing--zebra .table__body td {
      border-right: 1px solid #eeeef3; }
      .listing.listing--zebra .table__header th:last-child,
      .listing.listing--zebra .table__header td:last-child, .listing.listing--zebra .table__body th:last-child,
      .listing.listing--zebra .table__body td:last-child {
        border-right: none; }
  .listing.listing--table-fixed .table__list {
    table-layout: fixed; }
  .listing.listing--hide-table .table__list {
    display: none; }
