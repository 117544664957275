/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.fileuploader {
  width: 100%;
  display: block; }
  .fileuploader__icon {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto; }
    .fileuploader__icon svg,
    .fileuploader__icon .svg {
      width: 100%;
      display: block;
      fill: #3d3e42; }
  .fileuploader__text {
    display: block;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    margin: 20px auto 0 auto;
    color: #3d3e42; }
  .fileuploader__label {
    padding: 0;
    display: block;
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    margin: 8px 0 0 0;
    color: #848692; }
  .fileuploader__droparea {
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0 auto;
    position: relative;
    border-radius: 3px;
    text-align: center;
    padding: 40px;
    color: #848692;
    background-color: #eeeef3;
    border: 1px dashed #848692; }
  .fileuploader__droparea-inner {
    width: 100%;
    display: block; }
    .fileuploader__droparea-inner .button {
      margin-top: 8px;
      background-color: #ffffff; }
    .fileuploader__droparea-inner .react-fine-uploader-file-input {
      z-index: 2;
      cursor: pointer; }
  .fileuploader__droparea--active {
    border: 1px solid #3d3e42; }
  .fileuploader__filelist {
    width: 100%;
    display: block;
    margin-top: 16px; }
  .fileuploader__filelist-title {
    padding: 0;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0 0 8px 0;
    color: #3d3e42; }
  .fileuploader__filelist-items {
    margin: 0;
    padding: 0;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    list-style-type: none;
    color: #3d3e42; }
  .fileuploader__filelist-item {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 8px 0;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #eeeef3; }
    .fileuploader__filelist-item:last-child {
      border-bottom: none; }
  .fileuploader__filelist-item--deleting {
    opacity: 0.5;
    pointer-events: none; }
  .fileuploader__filelist-name {
    padding-right: 8px; }
  .fileuploader__filelist-remove {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    border-radius: 0;
    -webkit-appearance: none;
    background-color: transparent; }
    .fileuploader__filelist-remove .svg,
    .fileuploader__filelist-remove svg {
      width: 16px;
      height: 16px;
      display: block; }
    .fileuploader__filelist-remove svg {
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      fill: #3d3e42; }
