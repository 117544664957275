/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.sidebar {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 72px;
  width: 72px;
  min-height: 100vh;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start; }
  @media (max-width: 1300px) {
    .sidebar {
      width: 72px;
      flex: 0 0 72px; } }
  @media (max-width: 1030px) {
    .sidebar {
      top: 0;
      left: 0;
      right: 0;
      width: auto;
      z-index: 999;
      min-height: 0;
      flex: 0 0 auto;
      position: fixed; } }
  @media print {
    .sidebar {
      position: static !important; } }
  .sidebar__content {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 72px;
    z-index: 999;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start; }
    @media (max-width: 1300px) {
      .sidebar__content {
        max-width: 72px; } }
    @media (max-width: 1030px) {
      .sidebar__content {
        right: 0;
        bottom: auto;
        height: 56px;
        max-width: 100% !important; } }
    @media print {
      .sidebar__content {
        position: static !important; } }
  .sidebar__bolt {
    z-index: 10;
    position: relative;
    display: flex;
    width: 72px;
    flex-direction: column;
    flex-wrap: nowrap;
    color: #ffffff;
    fill: #ffffff;
    padding: 32px 16px;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, #000000, #323332); }
    @media (max-width: 1030px) {
      .sidebar__bolt {
        width: 100%;
        height: 100%;
        padding: 0; } }
    @media print {
      .sidebar__bolt {
        background: none; } }
    .sidebar__bolt-top, .sidebar__bolt-bottom {
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      display: block;
      text-align: center;
      list-style-type: none; }
      .sidebar__bolt-top-item, .sidebar__bolt-bottom-item {
        margin-top: 12px; }
        .sidebar__bolt-top-item .tooltip, .sidebar__bolt-bottom-item .tooltip {
          display: block;
          margin: 0 auto; }
    .sidebar__bolt-top {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      margin-bottom: 16px;
      flex-direction: column-reverse; }
      @media (max-width: 1030px) {
        .sidebar__bolt-top {
          height: 100%;
          margin-bottom: 0;
          flex-direction: row;
          padding-left: 16px;
          padding-right: 16px;
          justify-content: space-between; } }
    .sidebar__bolt-top-logo {
      margin-bottom: 32px; }
      @media (max-width: 1030px) {
        .sidebar__bolt-top-logo {
          margin-bottom: 0; } }
    .sidebar__bolt-top-profile {
      display: none; }
      @media (max-width: 1030px) {
        .sidebar__bolt-top-profile {
          display: block; } }
    @media (max-width: 1030px) {
      .sidebar__bolt-bottom {
        display: none; } }
  .sidebar__logo {
    width: 100%;
    max-width: 40px;
    height: auto;
    display: block;
    margin: 0 auto; }
    .sidebar__logo .svg {
      display: block;
      width: 100%;
      height: auto; }
    .sidebar__logo svg {
      display: block;
      fill: #ffffff;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .sidebar__logo:hover svg {
      -webkit-filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.5));
              filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.5)); }
  .sidebar__button {
    width: 36px;
    height: 36px;
    display: block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    padding: 8px;
    margin: 0 auto 8px auto;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    @media print {
      .sidebar__button {
        display: none; } }
    .sidebar__button:last-child {
      margin-bottom: 0px; }
    .sidebar__button .svg {
      display: block;
      width: 20px;
      height: 20px; }
      .sidebar__button .svg svg {
        display: block;
        fill: #ffffff; }
    .sidebar__button:hover {
      background-color: rgba(255, 255, 255, 0.15); }
  .sidebar__profile {
    width: 32px;
    height: 32px;
    display: block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    padding: 0;
    margin: 0 auto 0 auto;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    overflow: hidden; }
    @media print {
      .sidebar__profile {
        display: none; } }
    .sidebar__profile:hover {
      box-shadow: 0.5px 0.8px 0.8px 0.2px rgba(85, 99, 234, 0.41); }
    .sidebar__profile img {
      display: block;
      border-radius: 50%;
      overflow: hidden; }
    .sidebar__profile .svg {
      display: block;
      width: 100%;
      height: auto;
      fill: #dadae4;
      border-radius: 50%;
      overflow: hidden; }
  .sidebar__deck {
    position: relative;
    display: none;
    width: 256px;
    height: 100vh;
    flex-direction: column;
    padding: 24px 0 0 0;
    background-color: #ffffff;
    box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    overflow-x: hidden;
    z-index: 9; }
    @media print {
      .sidebar__deck {
        display: none !important; } }
    @media (max-width: 1030px) {
      .sidebar__deck {
        top: 0;
        left: 0;
        bottom: 0;
        position: fixed;
        padding-top: 80px; } }
  .sidebar__mobile-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    z-index: 10;
    opacity: 0.5;
    border: none;
    width: 100vw;
    display: none;
    position: fixed;
    border-radius: 0;
    pointer-events: none;
    background-color: black;
    -webkit-appearance: none;
    max-width: 100% !important; }
    @media print {
      .sidebar__mobile-overlay {
        display: none !important; } }
  .sidebar__mobile-profile {
    top: 0;
    right: 0;
    z-index: 9;
    display: none;
    position: fixed;
    width: 264px;
    background-color: white;
    padding-top: 56px;
    box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42); }
  .sidebar__profile-list {
    margin: 0;
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    list-style-type: none;
    padding: 24px 32px; }
  .sidebar__profile-list-item {
    padding-bottom: 24px; }
    .sidebar__profile-list-item:last-child {
      padding-bottom: 0; }
    .sidebar__profile-list-item a {
      text-decoration: none;
      color: #3d3e42; }
      .sidebar__profile-list-item a:hover {
        text-decoration: none; }
  .sidebar--menu-opened {
    flex-basis: 328px;
    width: 328px; }
    @media (max-width: 1030px) {
      .sidebar--menu-opened {
        width: auto;
        flex: 0 0 auto; } }
    .sidebar--menu-opened .sidebar__content {
      max-width: 328px; }
      @media (max-width: 1030px) {
        .sidebar--menu-opened .sidebar__content {
          max-width: 100%; } }
    @media (max-width: 1030px) {
      .sidebar--menu-opened .sidebar__mobile-overlay {
        display: block;
        pointer-events: all; } }
    .sidebar--menu-opened .sidebar__deck {
      display: flex; }
    .sidebar--menu-opened .sidebar__button--hamburger {
      background-color: rgba(255, 255, 255, 0.2); }
      .sidebar--menu-opened .sidebar__button--hamburger:hover {
        background-color: rgba(255, 255, 255, 0.35); }
  @media (max-width: 1030px) {
    .sidebar--profile-opened .sidebar__mobile-overlay {
      display: block;
      pointer-events: all; } }
  @media (max-width: 1030px) {
    .sidebar--profile-opened .sidebar__mobile-profile {
      display: block; } }
