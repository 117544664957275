/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.element-loader {
  display: inline-block;
  width: 34px;
  height: 8px; }
  .element-loader .svg {
    display: block;
    width: 100%;
    height: 100%; }
  .element-loader svg {
    display: block;
    width: 100%;
    height: 100%; }
  .element-loader .dot1 {
    fill: rgba(222, 16, 45, 0.7); }
  .element-loader .dot2 {
    fill: rgba(222, 16, 45, 0.4); }
  .element-loader .dot3 {
    fill: #de102d; }
  .element-loader--overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100; }
    .element-loader--overlay .svg {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center; }
    .element-loader--overlay svg {
      display: block;
      width: 34px;
      height: 8px; }
