/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.order-info {
  display: flex;
  flex-direction: column; }
  .order-info__container {
    display: flex;
    justify-content: space-around; }
    @media (max-width: 1030px) {
      .order-info__container {
        flex-wrap: wrap;
        margin-bottom: -16px; } }
  .order-info__data {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% / 6);
    width: calc(100% / 6); }
    @media (max-width: 1030px) {
      .order-info__data {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(100% / 3);
        padding-bottom: 16px; } }
    @media (max-width: 850px) {
      .order-info__data {
        flex: 1 1 50%; } }
    .order-info__data--header {
      color: #aaaabd;
      font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
      padding-bottom: 8px; }
    .order-info__data--body {
      font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0; }
