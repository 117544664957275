/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.bulk-actions {
  /*
    &__modal {
        .modal__title {
            // font: $font-2;
        }
    }
    */ }
  .bulk-actions-checkbox {
    display: flex;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-right: 16px; }
    .bulk-actions-checkbox:last-child {
      margin-right: 0; }
    .bulk-actions-checkbox__error {
      font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
      margin-left: 16px;
      margin-top: 8px;
      color: #fb6f75; }
    .bulk-actions-checkbox__label {
      display: flex;
      font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif;
      cursor: pointer;
      flex-wrap: nowrap;
      position: relative;
      flex-direction: row;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: flex-start; }
    .bulk-actions-checkbox__icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      border-radius: 3px;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      background-color: #ffffff;
      border: 2px solid #eeeef3; }
      .bulk-actions-checkbox__icon .svg {
        width: auto;
        height: auto;
        display: block; }
      .bulk-actions-checkbox__icon svg {
        width: 8px;
        margin-left: 2px;
        height: 12px;
        display: block;
        fill: #ffffff;
        opacity: 0;
        transform: scale(2);
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .bulk-actions-checkbox input {
      display: none; }
      .bulk-actions-checkbox input:checked + .bulk-actions-checkbox__label .bulk-actions-checkbox__icon {
        border: 2px solid #848692;
        background-color: #848692; }
        .bulk-actions-checkbox input:checked + .bulk-actions-checkbox__label .bulk-actions-checkbox__icon svg {
          opacity: 1;
          transform: scale(1); }
      .bulk-actions-checkbox input:disabled + .bulk-actions-checkbox__label {
        pointer-events: none;
        opacity: 0.6; }
        .bulk-actions-checkbox input:disabled + .bulk-actions-checkbox__label .bulk-actions-checkbox__icon {
          border: 2px solid #dadae4;
          background-color: #dadae4; }
          .bulk-actions-checkbox input:disabled + .bulk-actions-checkbox__label .bulk-actions-checkbox__icon svg {
            opacity: 0;
            transform: scale(0); }
  .bulk-actions-dropdown {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    align-items: center;
    max-width: 100%;
    height: 28px;
    margin: 0;
    border: none;
    border-radius: 3px;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    fill: #ffffff;
    background-image: none;
    background-color: #de102d;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    padding: 0;
    margin-right: 8px; }
    .bulk-actions-dropdown:hover, .bulk-actions-dropdown:active, .bulk-actions-dropdown:focus {
      text-decoration: none;
      color: #ffffff;
      background: #ff3e43; }
    .bulk-actions-dropdown__toggle {
      border: none;
      font: inherit;
      text-transform: inherit;
      text-align: inherit;
      text-decoration: none;
      color: inherit;
      background-image: none;
      background-color: transparent;
      border-radius: 3px;
      vertical-align: inherit;
      -webkit-user-select: inherit;
         -moz-user-select: inherit;
          -ms-user-select: inherit;
              user-select: inherit;
      touch-action: inherit;
      cursor: inherit;
      white-space: inherit;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      padding: 5px 8px; }
      .bulk-actions-dropdown__toggle:hover, .bulk-actions-dropdown__toggle:active, .bulk-actions-dropdown__toggle:focus {
        text-decoration: none;
        color: #ffffff; }
      .bulk-actions-dropdown__toggle-label {
        display: inline-block;
        margin-right: 8px; }
        .bulk-actions-dropdown__toggle-label:last-child {
          margin-right: 0; }
      .bulk-actions-dropdown__toggle-icon {
        display: block;
        width: 10px;
        height: 10px;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
        .bulk-actions-dropdown--open .bulk-actions-dropdown__toggle-icon {
          transform: rotate(180deg); }
        .bulk-actions-dropdown__toggle-icon svg {
          display: block;
          fill: #ffffff; }
      .bulk-actions-dropdown__toggle:disabled {
        color: #ffffff; }
    .bulk-actions-dropdown .react-select__control {
      display: none !important; }
    .bulk-actions-dropdown .react-select__option--is-focused {
      color: #aaaabd;
      background-color: transparent; }
    .bulk-actions-dropdown .react-select__option--is-selected {
      color: #848692;
      background-color: #eeeef3; }
    .bulk-actions-dropdown .react-select__menu {
      top: 100%;
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .bulk-actions-dropdown .react-select__option {
      padding: 8px 12px; }
