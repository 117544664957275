/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.group-item {
  position: relative;
  flex: 0 1 200px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 38px;
  padding: 5px 8px;
  margin: 0 8px 8px 0;
  border: 1px solid #dadae4;
  border-radius: 3px;
  background: #eeeef3;
  opacity: 1; }
  @media (max-width: 480px) {
    .group-item {
      flex: 0 1 80px;
      padding: 4px 6px;
      min-height: 33px;
      margin: 0 4px 4px 0; } }
  @media (min-width: 2465px) {
    .group-item {
      flex: 0 1 226px; } }
  .group-list .group-item {
    margin-bottom: 0;
    flex: 1 1 220px;
    min-width: 220px; }
  .group-item--complete {
    background: #ecfaf5;
    border-color: #bdecdd; }
  .group-item--incomplete {
    background: #fff5f5;
    border-color: #fdb9bc; }
  .group-item--is-dragging {
    box-shadow: 0px 0px 0px 2px rgba(119, 158, 224, 0.5); }
  .group-item__handle {
    width: 12px;
    height: 12px;
    margin-right: 8px; }
    .group-item__handle svg {
      width: 12px;
      height: 12px; }
    @media (max-width: 480px) {
      .group-item__handle {
        margin-right: 2px;
        margin-left: -2px; } }
  .group-item__data {
    flex: 1 1 100%;
    padding-right: 4px; }
    .group-item__data-name {
      margin: 0 0 6px;
      font: 700 10px/14px "Roboto", Helvetica, Arial, sans-serif;
      line-height: 1; }
      @media (max-width: 480px) {
        .group-item__data-name {
          font-size: 10px; } }
      @media (min-width: 1301px) {
        .group-item__data-name {
          font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; } }
      .group-item__data-name-icon {
        width: 10px;
        height: 10px;
        margin-right: 4px; }
        .group-item__data-name-icon svg {
          width: 10px;
          height: 10px; }
        .group-item__data-name-icon--alert svg {
          fill: #de102d; }
    .group-item__data-type {
      position: relative;
      top: -1px;
      display: inline-block;
      margin: 0 0 -2px;
      padding: 0 5px;
      font-size: 8px;
      line-height: 12px;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      border-radius: 6px;
      background: rgba(238, 238, 243, 0.5); }
      @media (min-width: 1301px) {
        .group-item__data-type {
          margin: 0 2px -2px 0;
          padding: 0 4px;
          font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
          line-height: 16px; } }
      .group-item__data-type--private {
        background: #f8cd9a; }
      .group-item__data-type--shared {
        background: #caccdf; }
    .group-item__data-ticket-types {
      margin: 0;
      font-size: 8px;
      line-height: 1.2;
      color: #848692; }
      @media (min-width: 1301px) {
        .group-item__data-ticket-types {
          font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; } }
    .group-item__data-table-name {
      margin: 0;
      font-size: 8px;
      line-height: 1.2;
      color: #3d3e42; }
      @media (min-width: 1301px) {
        .group-item__data-table-name {
          font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; } }
  .group-item__count {
    flex: 1 0 auto;
    margin: 0;
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif; }
    @media (max-width: 480px) {
      .group-item__count {
        margin-top: -2px;
        font: 700 16px/20px "Roboto", Helvetica, Arial, sans-serif; } }
  .group-item__options {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background: #eeeef3;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    pointer-events: none;
    visibility: hidden; }
    .group-item:hover .group-item__options {
      opacity: 1;
      transition-delay: 0.1s;
      -webkit-animation: delay-group-pointer-events 0.1s linear;
              animation: delay-group-pointer-events 0.1s linear;
      pointer-events: auto;
      visibility: visible; }
    .group-item__options-move {
      width: 12px;
      height: 12px;
      margin-right: 8px; }
      .group-item__options-move svg {
        width: 12px;
        height: 12px; }
      .group-item--handle-is-displayed .group-item__options-move {
        display: none; }
    .group-item__options .button {
      margin-top: 1px;
      margin-bottom: 1px; }
      @media (max-width: 1300px) {
        .group-item__options .button {
          font-size: 8px;
          padding: 4px 6px;
          height: 16px;
          line-height: 1; } }
    .group-item--complete .group-item__options {
      background: #ecfaf5; }
    .group-item--incomplete .group-item__options {
      background: #fff5f5; }
    .group-item--handle-is-displayed .group-item__options {
      left: 24px;
      width: calc(100% - 24px); }

@-webkit-keyframes delay-group-pointer-events {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

@keyframes delay-group-pointer-events {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  100% {
    visibility: visible; } }
