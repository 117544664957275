/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.events-table-event-info {
  position: relative;
  display: flex;
  flex-direction: column; }
  .events-table-event-info__title {
    text-transform: capitalize;
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .events-table-event-info__start {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692; }
  .events-table-event-info__start-title {
    font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    color: #3d3e42; }
  .events-table-event-info__end {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692; }
  .events-table-event-info__end-title {
    font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    color: #3d3e42; }
