/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.break {
  height: 1px;
  background-color: rgba(132, 134, 146, 0.15);
  margin: 0 16px; }

.customer-info-accordion {
  background: #fafafb;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0 rgba(61, 62, 66, 0.1); }

.group-info__name {
  font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .group-info__name--link {
    vertical-align: top;
    padding: none;
    border: none;
    padding: 0;
    margin-left: 4px;
    cursor: pointer; }
  .group-info__name--icon {
    height: 11px;
    width: 11px;
    fill: #de102d; }

.group-info__merge--button {
  padding: none;
  border: none;
  padding: 0;
  margin-left: 4px; }

.cardholder-info-accordion {
  display: flex;
  flex-direction: column; }
  .cardholder-info-accordion__name {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }

.group-info-accordion {
  display: flex; }
  .group-info-accordion__merge-groups {
    margin-top: 8px; }
  .group-info-accordion__merge-groups-button {
    padding: 0 !important; }
  .group-info-accordion__title {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    color: #aaaabd;
    margin-bottom: 4px; }
  .group-info-accordion__data {
    font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .group-info-accordion__available {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif; }
    .group-info-accordion__available--bold {
      font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .group-info-accordion__column {
    flex: 1 1 auto;
    justify-content: space-between;
    flex-direction: column;
    display: flex; }
  .group-info-accordion__rectangle {
    height: 40px;
    width: 40px;
    border-radius: 3px;
    background: #81dbbe;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif; }
  .group-info-accordion__id {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px; }
  .group-info-accordion__table {
    display: flex;
    flex-direction: column; }

.payment-info-accordion {
  display: flex;
  flex-direction: column; }
  .payment-info-accordion__status--text {
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .payment-info-accordion__status--icon {
    width: 15px;
    height: 13px;
    fill: #81dbbe; }
  .payment-info-accordion__button {
    margin-top: 16px; }

.hubspot-accordion {
  display: flex;
  flex-direction: column; }
