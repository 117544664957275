/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.filter-date-range {
  width: 100%; }
  .filter-date-range__label {
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin-bottom: 16px; }
  .filter-date-range__field {
    margin-bottom: 16px; }
    .filter-date-range__field:last-child {
      margin-bottom: 0px; }
