/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.activity-log__filters {
  margin-bottom: 32px; }

.activity-log__content:not(:last-of-type) {
  margin-bottom: 24px; }

.activity-log__content p {
  margin-bottom: 8px; }

.activity-log__title span {
  margin-right: 24px; }
  .activity-log__title span:last-of-type {
    margin-right: 0; }
