/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.input-switch {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 16px; }
  .input-switch--row {
    margin-bottom: 0; }
  .input-switch__error {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    width: 100%;
    margin-left: 16px;
    margin-top: 8px;
    color: #fb6f75; }
  .input-switch__col {
    padding-right: 7px; }
    .input-switch__col:last-child {
      padding-right: 0; }
  .input-switch__toggle {
    width: 32px;
    height: 16px;
    display: block;
    font-size: 13px;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    background-color: white;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    border: 2px solid #eeeef3; }
    .input-switch__toggle:before {
      top: -2px;
      left: -2px;
      z-index: 2;
      width: 16px;
      height: 16px;
      content: '\A';
      position: absolute;
      border-radius: 100%;
      transform: translateX(0px);
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      background-color: #848692; }
  .input-switch__toggle--active:before {
    transform: translateX(16px); }
  .input-switch__toggle--green {
    border: 2px solid #81dbbe;
    background-color: #81dbbe; }
  .input-switch__checkbox {
    display: none; }
  .input-switch__option {
    cursor: pointer;
    display: block;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    color: #aaaabd; }
  .input-switch__option--active {
    color: #3d3e42; }
  .input-switch__option--clickable {
    cursor: pointer; }
