/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

@media (max-width: 850px) {
  .total-sales-by-event-bar-widget .widget__content {
    overflow: auto; } }

.total-sales-by-event-bar-widget .widget__header {
  margin-bottom: 8px; }

.total-sales-by-event-bar-widget__toggles {
  margin-bottom: 16px; }
  .total-sales-by-event-bar-widget__toggles-button {
    color: #3d3e42;
    padding: 0;
    height: auto;
    font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 100; }
    .total-sales-by-event-bar-widget__toggles-button--active {
      color: #ff3e43; }

.total-sales-by-event-bar-widget__content {
  display: flex;
  width: 100%;
  flex-basis: 100%;
  flex-direction: column; }
  @media (max-width: 850px) {
    .total-sales-by-event-bar-widget__content {
      width: 800px;
      min-width: 800px;
      max-width: 800px; } }

.total-sales-by-event-bar-widget .horizontal-bar-chart {
  max-height: 440px;
  min-height: 440px; }

.total-sales-by-event-bar-widget__main {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding-right: 24px;
  min-height: 296px; }
