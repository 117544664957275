/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.table-item {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  margin: 0 0 8px;
  border: 1px solid #dadae4;
  border-radius: 3px;
  background: #ffffff;
  opacity: 1;
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
  /**
     * Groups
     */ }
  @media (max-width: 480px) {
    .table-item {
      flex-wrap: wrap; } }
  .table-item--locked {
    background: #fafafb; }
  .table-item--is-dragging-from-this {
    box-shadow: 0px 0px 0px 2px rgba(119, 158, 224, 0.5); }
  .table-item--is-dragging-over {
    box-shadow: 0px 0px 0px 2px rgba(129, 219, 190, 0.5); }
  .table-item__alert {
    position: absolute;
    top: 8px;
    left: -24px;
    width: 16px;
    height: 16px; }
    .table-item__alert .svg {
      width: 16px;
      height: 16px; }
      .table-item__alert .svg svg {
        width: 16px;
        height: 16px;
        fill: #fb6f75; }
  .table-item__stats {
    flex: 1 1 auto; }
    @media (max-width: 480px) {
      .table-item__stats {
        flex: 0 0 40px;
        order: 0; } }
    .table-item__stats-seats {
      display: block;
      min-width: 40px;
      height: 16px;
      margin: 0 0 8px;
      padding: 0 4px;
      font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
      line-height: 16px;
      white-space: nowrap;
      text-align: center;
      border-radius: 3px;
      background: #fdbec1; }
      .table-item__stats-seats--fully-occupied {
        background: #a9e6d2; }
      .table-item__stats-seats--over-occupied {
        background: #fb6f75; }
    .table-item__stats-type {
      display: block;
      width: 100%;
      height: 12px;
      padding: 0 4px;
      font-size: 8px;
      line-height: 12px;
      white-space: nowrap;
      text-align: center;
      border-radius: 3px;
      background: rgba(238, 238, 243, 0.5); }
      @media (min-width: 1301px) {
        .table-item__stats-type {
          height: 16px;
          font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
          line-height: 16px; } }
      .table-item__stats-type--private {
        background: #f8cd9a; }
      .table-item__stats-type--shared {
        background: #caccdf; }
  .table-item__name {
    flex: 1 0 72px;
    margin: 0;
    padding: 0 8px;
    font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    line-height: 16px; }
    @media (max-width: 480px) {
      .table-item__name {
        order: 1; } }
  .table-item__groups {
    position: relative;
    flex: 1 1 100%;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 -8px;
    min-height: 47px;
    overflow: visible; }
    @media (max-width: 480px) {
      .table-item__groups {
        flex: 0 0 100%;
        order: 3;
        padding-top: 8px; } }
    .table-item--is-dragging-over .table-item__groups .group-item__options {
      opacity: 0;
      pointer-events: none; }
    .table-item__groups-chunk {
      flex: 1 0 100%;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start; }
    .table-item__groups:after {
      position: absolute;
      content: 'Empty Table';
      padding-top: 9px;
      text-transform: uppercase;
      font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
      color: #dadae4;
      opacity: 0;
      transform: translate(-24px, 0);
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      pointer-events: none; }
      @media (min-width: 1301px) {
        .table-item__groups:after {
          font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif; } }
    .table-item__groups:empty:after {
      opacity: 1;
      transform: translate(0, 0); }
  .table-item__actions {
    flex: 0 0 63px;
    align-self: stretch;
    display: flex; }
    @media (max-width: 480px) {
      .table-item__actions {
        order: 2; } }
    .table-item__actions-split {
      margin-left: 8px; }
    .table-item__actions-unlocked {
      text-align: right;
      margin-bottom: 8px; }
      .table-item__actions-unlocked .button .svg {
        width: 8px;
        height: 8px; }
        @media (min-width: 1301px) {
          .table-item__actions-unlocked .button .svg {
            width: 12px;
            height: 12px; } }
        .table-item__actions-unlocked .button .svg svg {
          width: 8px;
          height: 8px;
          fill: #3d3e42; }
          @media (min-width: 1301px) {
            .table-item__actions-unlocked .button .svg svg {
              width: 12px;
              height: 12px; } }
    .table-item__actions-locked {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;
      margin-bottom: 8px; }
      .table-item__actions-locked-info {
        margin: 0;
        white-space: nowrap;
        color: #de102d; }
        @media (max-width: 1300px) {
          .table-item__actions-locked-info {
            font-size: 8px; } }
        .table-item__actions-locked-info .svg {
          width: 8px;
          height: 8px;
          margin-right: 6px; }
          @media (min-width: 1301px) {
            .table-item__actions-locked-info .svg {
              width: 12px;
              height: 12px; } }
          .table-item__actions-locked-info .svg svg {
            width: 8px;
            height: 8px;
            fill: #de102d; }
            @media (min-width: 1301px) {
              .table-item__actions-locked-info .svg svg {
                width: 12px;
                height: 12px; } }
      .table-item__actions-locked-buttons .button {
        padding-left: 0;
        padding-right: 0;
        color: #3d3e42; }
        @media (max-width: 1300px) {
          .table-item__actions-locked-buttons .button {
            font-size: 8px;
            padding: 2px 0;
            height: 12px;
            line-height: 1; } }
    .table-item__actions-note-edit {
      padding-left: 16px !important;
      padding-right: 16px !important;
      color: #3d3e42; }
      [data-locked="1"] .table-item__actions-note-edit {
        height: auto;
        line-height: 1;
        padding: 0;
        margin: -2px 0 0 0; }
      @media (max-width: 1300px) {
        [data-locked="0"] .table-item__actions-note-edit {
          height: 16px;
          padding-top: 2px;
          padding-bottom: 0;
          line-height: 1; } }
      .table-item__actions-note-edit .svg {
        width: 12px;
        height: 12px; }
        .table-item__actions-note-edit .svg svg {
          width: 12px;
          height: 12px;
          fill: #3d3e42; }
    .table-item__actions-note-add {
      color: #3d3e42; }
      [data-locked="1"] .table-item__actions-note-add {
        height: 18px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1; }
        @media (max-width: 1300px) {
          [data-locked="1"] .table-item__actions-note-add {
            font-size: 8px;
            padding: 4px 0;
            height: 12px;
            line-height: 1; } }
      @media (max-width: 1300px) {
        [data-locked="0"] .table-item__actions-note-add {
          font-size: 8px;
          padding: 6px 0;
          height: 12px;
          line-height: 1; } }
