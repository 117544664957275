/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.year-over-year__chart {
  height: 450px;
  min-height: 450px;
  box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
  padding: 20px;
  margin-bottom: 40px;
  position: relative; }
  @media (max-width: 850px) {
    .year-over-year__chart {
      overflow-x: scroll;
      overflow-y: hidden; } }
  .year-over-year__chart .line-chart {
    max-width: 100%; }
    @media (max-width: 850px) {
      .year-over-year__chart .line-chart {
        width: 1000px;
        max-width: unset;
        overflow: hidden; } }
    @media (max-width: 480px) {
      .year-over-year__chart .line-chart {
        width: 800px;
        max-width: unset;
        overflow: hidden; } }

@media (max-width: 480px) {
  .year-over-year__filter-form .form__columns .form__column {
    flex-direction: column; }
    .year-over-year__filter-form .form__columns .form__column .input {
      margin-bottom: 24px; }
      .year-over-year__filter-form .form__columns .form__column .input:first-child {
        order: 3;
        margin-bottom: 0; }
      .year-over-year__filter-form .form__columns .form__column .input:nth-child(2) {
        order: 2; }
      .year-over-year__filter-form .form__columns .form__column .input:last-child {
        order: 1; } }

.year-over-year__event-details-table .table__header {
  text-align: right; }
  .year-over-year__event-details-table .table__header h4 {
    margin: 0; }

.year-over-year__accordion .table__body-row td:first-of-type {
  width: 320px; }
  @media (max-width: 1030px) {
    .year-over-year__accordion .table__body-row td:first-of-type {
      width: auto; } }
