/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.table-allotments {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 142px; }
  @media (min-width: 1601px) {
    .table-allotments {
      padding-bottom: 0 !important; } }
  .table-allotments__totals {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 0 24px;
    padding: 12px 16px;
    border-radius: 3px;
    background: #fafafb;
    box-shadow: 0 2px 3px 0 rgba(61, 62, 66, 0.1);
    z-index: 3; }
    @media (max-width: 1030px) {
      .table-allotments__totals {
        flex-wrap: wrap; } }
    @media (max-width: 480px) {
      .table-allotments__totals {
        padding: 4px 6px; } }
    .table-allotments__totals-col {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin: 0 16px 0 0; }
      .table-allotments__totals-col:last-child {
        margin-right: 0; }
      @media (max-width: 1030px) {
        .table-allotments__totals-col {
          width: 100%; }
          .table-allotments__totals-col:nth-child(2) {
            justify-content: flex-start; } }
      @media (max-width: 480px) {
        .table-allotments__totals-col {
          flex-wrap: wrap;
          justify-content: flex-start; } }
    .table-allotments__totals-item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin: 0 16px 0 0; }
      .table-allotments__totals-item:last-child {
        margin-right: 0; }
      .table-allotments__totals-item-subject {
        display: block;
        font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
        margin-right: 8px;
        color: #848692; }
        @media (max-width: 480px) {
          .table-allotments__totals-item-subject {
            font-size: 9px; } }
        @media (min-width: 1301px) {
          .table-allotments__totals-item-subject {
            font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif; } }
      .table-allotments__totals-item-value {
        display: block;
        font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
        color: #3d3e42; }
        .table-allotments__totals-item--positive .table-allotments__totals-item-value {
          color: #37bf93; }
        .table-allotments__totals-item--negative .table-allotments__totals-item-value {
          color: #fa3d45; }
        @media (max-width: 850px) {
          .table-allotments__totals-item-value {
            font: 700 16px/20px "Roboto", Helvetica, Arial, sans-serif; } }
        @media (max-width: 480px) {
          .table-allotments__totals-item-value {
            font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif; } }
  .table-allotments__event {
    position: relative;
    display: flex;
    margin: 0 0 16px; }
  .table-allotments__gallery {
    display: block;
    margin: 0 0 16px; }
  .table-allotments__title {
    position: relative;
    margin: 0;
    z-index: 3; }
  .table-allotments__filters {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
    padding: 16px 0;
    background: #ffffff;
    z-index: 2; }
    @media (max-width: 1030px) {
      .table-allotments__filters {
        max-width: 100%;
        overflow: auto;
        top: 56px; } }
    @media (min-width: 1031px) {
      .table-allotments__filters .react-select__control {
        min-width: 116px; } }
  @media (max-width: 1030px) {
    .table-allotments .form__columns {
      flex-wrap: nowrap; } }
  .table-allotments .form__columns .form__column {
    margin-right: 16px; }
    @media (max-width: 1030px) {
      .table-allotments .form__columns .form__column {
        margin-bottom: 0; } }
    .table-allotments .form__columns .form__column:last-child {
      margin-right: 0; }
    @media (max-width: 1030px) {
      .table-allotments .form__columns .form__column .input {
        min-width: 140px; } }
  .table-allotments .input-switch {
    margin-bottom: 0; }
  .table-allotments__table-list {
    display: block;
    height: 100%;
    width: calc(100% + 24px);
    max-width: calc(100% + 24px);
    margin-left: -24px;
    padding-top: 4px;
    padding-left: 24px;
    flex: 1 0 300px;
    overflow: auto; }
  .table-allotments__print-button {
    margin-bottom: 24px; }
    @media (max-width: 480px) {
      .table-allotments__print-button > * {
        width: 100%;
        flex-basis: 100%;
        margin-bottom: 16px; }
        .table-allotments__print-button > *:last-child {
          margin-bottom: 0; } }

.table-allotments-fullscreen-button {
  margin-right: 0; }
  body.table-allotments-fullscreen .table-allotments-fullscreen-button--on {
    display: none; }
  .table-allotments-fullscreen-button--off {
    display: none; }
    body.table-allotments-fullscreen .table-allotments-fullscreen-button--off {
      display: inline-block; }

body.table-allotments-fullscreen #wrapper {
  height: 100%;
  overflow: auto; }

body.table-allotments-fullscreen .main {
  padding-top: 0;
  background: #fff; }

body.table-allotments-fullscreen .content {
  height: 100%; }

body.table-allotments-fullscreen .sidebar {
  display: none; }

body.table-allotments-fullscreen .table-allotments > * {
  display: none; }

body.table-allotments-fullscreen .table-allotments .table-allotments__event {
  display: flex; }

body.table-allotments-fullscreen .table-allotments__table-list {
  display: block; }

body.table-allotments-fullscreen .table-allotments .groups-footer {
  display: block;
  width: 100%;
  max-width: 100%; }

.group-modal-content {
  display: block; }
  .group-modal-content .status-badge {
    margin: 0; }
