/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.form {
  margin: 0px 0px 24px 0px; }
  .form:last-child {
    margin-bottom: 0px; }
  .form__column {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
    width: 100%;
    border: none;
    padding: 0px;
    margin: 0px; }
    .form__column--auto-width {
      width: auto; }
  .form__section-title {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin: 16px 0px 24px 0px; }
    .form__section-title:first-child {
      margin-top: 0px; }
  .form__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: 0px 0px 16px 0px; }
    @media (max-width: 1030px) {
      .form__row {
        display: block; } }
    .form__row--vertical-center {
      align-items: center; }
    .form__row:last-child, .form__row:empty {
      margin-bottom: 0px; }
    .form__row-column {
      width: 50%;
      flex: 0 0 50%;
      padding-left: 8px;
      padding-right: 8px; }
      @media (max-width: 1030px) {
        .form__row-column {
          width: 100%;
          flex: 0 0 100%;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 16px; } }
      .form__row-column:first-child, .form__row-column:empty {
        padding-left: 0; }
      .form__row-column:last-child, .form__row-column:empty {
        padding-right: 0; }
        @media (max-width: 1030px) {
          .form__row-column:last-child, .form__row-column:empty {
            margin-bottom: 0; } }
      .form__row-column-responsive {
        width: auto;
        display: block; }
        @media (max-width: 1030px) {
          .form__row-column-responsive {
            margin-bottom: 16px; } }
        @media (max-width: 1030px) {
          .form__row-column-responsive:last-child {
            margin-bottom: 0; } }
  .form__columns, .form--with-columns {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px; }
    .form__columns:last-child, .form--with-columns:last-child {
      margin-bottom: 0px; }
    .form__columns .form__column, .form--with-columns .form__column {
      flex-basis: 50%;
      width: 50%;
      margin-right: 32px; }
      .form__columns .form__column--auto-width, .form--with-columns .form__column--auto-width {
        flex-basis: auto;
        width: auto;
        flex-grow: 0; }
      .form__columns .form__column:last-child, .form--with-columns .form__column:last-child {
        margin-right: 0px; }
    @media (max-width: 1030px) {
      .form__columns--break-on-mobile, .form--with-columns--break-on-mobile {
        flex-wrap: wrap; }
        .form__columns--break-on-mobile > .form__column, .form--with-columns--break-on-mobile > .form__column {
          flex-basis: 100%;
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px; }
          .form__columns--break-on-mobile > .form__column:last-child, .form--with-columns--break-on-mobile > .form__column:last-child {
            margin-bottom: 0; } }
  .form__help-text {
    text-align: right; }
    @media (max-width: 1030px) {
      .form__help-text {
        text-align: left; } }
  .form--inline {
    display: inline; }
    .form--inline:not(:last-child) {
      margin-right: 8px; }
  .form--view-only .input__label {
    left: 0px;
    transform: translate(0px, -11px);
    padding-left: 0px; }
  .form--view-only .input input, .form--view-only .input__field {
    background-color: transparent;
    border: none;
    padding: 8px 0px;
    height: auto;
    min-height: auto; }
  .form--view-only .input--disabled {
    opacity: 1; }
  .form--view-only .react-select--is-disabled .react-select__control {
    background-color: transparent;
    border: none;
    padding: 0px; }
  .form--view-only .react-select--is-disabled .react-select__indicators {
    display: none; }
  .form--view-only .react-phone-number-input.input__field {
    padding: 0px; }
  .form--view-only .react-phone-number-input__country--native {
    display: none; }
