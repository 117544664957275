/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.ticket-type-repeater-item__ticket-name {
  flex-basis: 120% !important; }

.ticket-type-repeater-item__merge-ticket-types__button {
  position: absolute;
  bottom: -11px;
  right: 5px; }
  .ticket-type-repeater-item__merge-ticket-types__button .button__icon svg {
    fill: #3d3e42; }
  .ticket-type-repeater-item__merge-ticket-types__button:hover .button__icon svg {
    fill: #ff3e43; }

.ticket-type-repeater-item__event-includes {
  padding: 16px;
  background-color: #fafafb;
  width: 100%;
  margin-bottom: 16px; }
