/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.page-header {
  position: relative;
  display: flex;
  width: 100%;
  min-height: 88px;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  padding: 24px 0; }
  .page-header__title {
    flex: 1 1;
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0 0 8px 0; }
    .page-header__title:last-child {
      margin-bottom: 0; }
  .page-header__subtitle {
    margin-bottom: 0; }
  .page-header__content {
    margin-right: auto; }
  .page-header__back {
    height: 100%;
    padding-top: 0;
    margin-left: -16px;
    display: flex;
    align-items: center; }
    @media (min-width: 1031px) {
      .page-header__back {
        position: absolute;
        left: -56px;
        bottom: 0;
        margin-left: 0;
        z-index: 10; } }
    .page-header__back-button {
      background-color: transparent;
      border: none;
      color: #3d3e42;
      fill: #3d3e42; }
      .page-header__back-button:hover {
        background-color: transparent;
        border: none;
        color: #3d3e42;
        fill: #3d3e42; }
      .page-header__back-button .button__icon {
        fill: #3d3e42; }
        .page-header__back-button .button__icon svg {
          fill: #3d3e42; }
  .page-header__favorite {
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    padding: 0;
    margin: 0 auto 0 16px;
    border: none;
    background-color: transparent;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .page-header__favorite .svg {
      display: block;
      width: 100%;
      height: auto; }
      .page-header__favorite .svg svg {
        display: block;
        fill: #dadae4;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
  .page-header__actions {
    display: flex; }
    .page-header__actions-dots {
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 100%;
      align-items: center;
      align-content: center;
      justify-content: center;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      background-color: transparent; }
      .page-header__actions-dots svg {
        width: 4px;
        margin: 0 auto;
        display: block;
        fill: #aaaabd; }
      @media (min-width: 1031px) {
        .page-header__actions-dots {
          display: none; } }
    @media (max-width: 1030px) {
      .page-header__actions {
        width: 24px;
        height: 40px;
        float: right;
        display: block;
        cursor: pointer;
        position: relative;
        align-self: flex-start; }
        .page-header__actions:hover .page-header__actions-dots {
          background-color: #3d3e42; }
        .page-header__actions:hover .page-header__actions-items {
          opacity: 1;
          visibility: visible;
          pointer-events: all; } }
    .page-header__actions-items {
      display: flex; }
      @media (max-width: 1030px) {
        .page-header__actions-items {
          height: 40px;
          background: #ffffff;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          bottom: 0;
          opacity: 0;
          display: flex;
          flex-wrap: nowrap;
          position: absolute;
          visibility: hidden;
          align-items: center;
          pointer-events: none;
          align-content: center;
          list-style-type: none;
          transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
          justify-content: flex-start; } }
