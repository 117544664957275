/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.event-tables-repeater-item__can-be-grouped-with {
  flex-basis: 80% !important; }

.event-tables-repeater-item__merge-tables__button {
  position: absolute;
  bottom: -11px;
  right: 5px; }
  .event-tables-repeater-item__merge-tables__button .button__icon svg {
    fill: #3d3e42; }
  .event-tables-repeater-item__merge-tables__button:hover .button__icon svg {
    fill: #ff3e43; }
