/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.groups-table-event-info {
  position: relative;
  display: flex;
  flex-direction: column; }
  .groups-table-event-info__name {
    text-transform: capitalize; }
  .groups-table-event-info__ticket {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692; }
