/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.event-info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #eeeef3;
  border-radius: 3px;
  padding: 24px;
  background-color: white; }
  .event-info__title {
    display: blocK;
    width: 100%;
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
    padding-right: 80px; }
  .event-info__status {
    position: absolute;
    top: 24px;
    right: 24px;
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 4px 8px;
    z-index: 1; }
  .event-info__content {
    display: block;
    width: 100%;
    margin-bottom: 16px; }
    .event-info__content:last-child {
      margin-bottom: 0px; }
  .event-info__venue, .event-info__date {
    color: #848692;
    margin: 0px; }
  .event-info__actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap; }
    @media (max-width: 850px) {
      .event-info__actions {
        flex-direction: column; } }
    @media (max-width: 850px) {
      .event-info__actions-secondary {
        margin-top: 8px; }
        .event-info__actions-secondary :first-child {
          padding-left: 0px;
          margin-right: 0px; } }
