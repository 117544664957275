@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto.eot');
    src: url('/fonts/Roboto.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Roboto.woff2') format('woff2'), url('/fonts/Roboto.woff') format('woff'),
        url('/fonts/Roboto.ttf') format('truetype'), url('/fonts/Roboto.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('/fonts/Roboto-Bold.eot');
    src: url('/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Roboto-Bold.woff2') format('woff2'),
        url('/fonts/Roboto-Bold.woff') format('woff'),
        url('/fonts/Roboto-Bold.ttf') format('truetype'),
        url('/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}
