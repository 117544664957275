/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.questions-info-header {
  display: flex;
  justify-content: space-between;
  font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .questions-info-header span {
    text-align: center;
    width: 150px; }
    .questions-info-header span:first-child {
      text-align: left; }
    .questions-info-header span:last-child {
      text-align: right; }

.questions-info-body {
  display: flex;
  flex-direction: column; }
  .questions-info-body__question {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .questions-info-body__question--link {
      vertical-align: top; }
    .questions-info-body__question--icon {
      height: 11px;
      width: 11px;
      fill: #de102d; }
  .questions-info-body__answer {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    justify-content: space-between;
    margin-bottom: 16px; }
    .questions-info-body__answer:last-child {
      padding-bottom: 0; }
  .questions-info-body__details {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px; }
    .questions-info-body__details div {
      text-align: center;
      width: 150px; }
      .questions-info-body__details div:first-child {
        text-align: left; }
      .questions-info-body__details div:last-child {
        text-align: right; }
    .questions-info-body__details:last-child {
      padding-bottom: 0; }

.questions-info-footer {
  display: flex;
  flex-direction: column; }
  .questions-info-footer__subtotal {
    display: flex;
    justify-content: space-between;
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    padding-bottom: 8px; }
  .questions-info-footer__fee {
    display: flex;
    justify-content: space-between;
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    padding-bottom: 8px; }
  .questions-info-footer__total {
    display: flex;
    justify-content: space-between;
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .questions-info-footer__total--bold {
      font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif; }
