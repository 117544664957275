/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.input-checkbox {
  display: flex;
  margin-bottom: 16px;
  margin-right: 16px; }
  .input-checkbox:last-child {
    margin-right: 0; }
  .input-checkbox__error {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    margin-left: 16px;
    margin-top: 8px;
    color: #fb6f75; }
  .input-checkbox__label {
    display: flex;
    font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    cursor: pointer;
    flex-wrap: nowrap;
    position: relative;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start; }
  .input-checkbox__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 3px;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    background-color: #ffffff;
    border: 2px solid #eeeef3; }
    .input-checkbox__icon .svg {
      width: auto;
      height: auto;
      display: block; }
    .input-checkbox__icon svg {
      width: 8px;
      margin-left: 2px;
      height: 12px;
      display: block;
      fill: #ffffff;
      opacity: 0;
      transform: scale(2);
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
  .input-checkbox input {
    display: none; }
    .input-checkbox input:checked + .input-checkbox__label .input-checkbox__icon {
      border: 2px solid #848692;
      background-color: #848692; }
      .input-checkbox input:checked + .input-checkbox__label .input-checkbox__icon svg {
        opacity: 1;
        transform: scale(1); }
    .input-checkbox input:disabled + .input-checkbox__label {
      pointer-events: none;
      opacity: 0.6; }
      .input-checkbox input:disabled + .input-checkbox__label .input-checkbox__icon {
        border: 2px solid #dadae4;
        background-color: #dadae4; }
        .input-checkbox input:disabled + .input-checkbox__label .input-checkbox__icon svg {
          opacity: 0;
          transform: scale(0); }
