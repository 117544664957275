/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.horizontal-line-chart {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px; }
  .horizontal-line-chart:last-of-type {
    margin-bottom: 0; }
  .horizontal-line-chart__title {
    margin-bottom: 12px; }
  .horizontal-line-chart__singleBar {
    width: 100%;
    margin-bottom: 2.66667px;
    transition: width 0.7s ease; }
    .horizontal-line-chart__singleBar .tooltip {
      width: 100%;
      height: 100%; }
      .horizontal-line-chart__singleBar .tooltip span {
        width: 0;
        transition: width 0.7s ease;
        border-radius: 5px;
        height: 12px;
        min-height: 12px;
        display: inline-block;
        width: 100%; }
    .horizontal-line-chart__singleBar:last-child {
      margin-bottom: 0; }
