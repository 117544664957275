/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.customer-info {
  display: flex;
  flex-direction: column; }
  .customer-info--opened .customer-info__address {
    max-height: 100rem;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: max-height; }
  .customer-info--opened .customer-info__content {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    transition-property: opacity, transform; }
  .customer-info__info {
    display: flex;
    flex-direction: column; }
  .customer-info__name {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .customer-info__name--link {
      vertical-align: top;
      padding: none;
      border: none;
      padding: 0;
      margin-left: 4px;
      cursor: pointer; }
    .customer-info__name--icon {
      height: 11px;
      width: 11px;
      fill: #de102d; }
  .customer-info__break {
    height: 1px;
    background-color: rgba(132, 134, 146, 0.15);
    margin: 16px 0; }
  .customer-info__action > button {
    padding: 0; }
  .customer-info__address {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    border-radius: 3px; }
  .customer-info__content {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    background-color: #fafafb;
    border-radius: 0 0 3px 3px; }
