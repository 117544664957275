/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.groups-listing__order-ids {
  max-width: 60px;
  font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .groups-listing__order-ids__link {
    text-decoration: none;
    color: #000; }
    .groups-listing__order-ids__link:hover {
      text-decoration: none; }
