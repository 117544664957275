/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.input-checkbox-group {
  display: flex;
  flex-basis: 100%;
  flex-direction: column; }
  .input-checkbox-group--inline-label {
    flex-direction: row; }
    .input-checkbox-group--inline-label .input-checkbox-group__label {
      flex-basis: auto; }
    .input-checkbox-group--inline-label .input-checkbox-group__content {
      flex-basis: auto; }
  .input-checkbox-group__label {
    display: block;
    flex-basis: 100%;
    margin-bottom: 16px;
    margin-right: 16px; }
  .input-checkbox-group__content {
    display: flex;
    flex-basis: 100%;
    justify-content: left;
    flex-wrap: wrap; }
