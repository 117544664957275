/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.error-404 {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1; }
  .error-404__wrapper {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: 432px; }
  .error-404__icon {
    display: block;
    width: 100%;
    height: auto; }
  .error-404__title {
    margin: 0 0 16px 0;
    font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
    color: #3d3e42; }
  .error-404__content {
    display: inline-block;
    position: absolute;
    bottom: 40px;
    left: 0; }
    @media (max-width: 1030px) {
      .error-404__content {
        bottom: 0; } }
