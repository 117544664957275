/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

* {
  box-sizing: border-box; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch; }

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }

body {
  font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
  color: #3d3e42;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #de102d;
  text-decoration: none;
  transition: 0.2s; }
  a:hover {
    color: #ff3e43;
    text-decoration: underline; }
  a:active {
    color: #ff3e43;
    text-decoration: none; }

button * {
  pointer-events: none; }

p,
ul,
ol {
  margin: 0 0 24px; }

p:last-child,
ul:last-child,
ol:last-child {
  margin-bottom: 0; }

a,
a img,
button {
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }

table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 8px; }

figure {
  position: relative;
  margin: 0;
  overflow: hidden;
  background-size: cover;
  background-position: center; }

figure img {
  display: block;
  width: 100%;
  height: auto; }

img {
  max-width: 100%;
  height: auto;
  border: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; }

svg:not(:root) {
  overflow: hidden; }
  svg:not(:root) > * {
    pointer-events: none; }
