/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.info {
  display: flex;
  width: 100%; }
  @media (max-width: 850px) {
    .info {
      flex-direction: column; } }
  .info__payment {
    flex: 2 1;
    margin-right: 16px; }
    @media (max-width: 850px) {
      .info__payment {
        margin-right: 0; } }
  .info__customer {
    flex: 1 1; }
  .info__title {
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
    margin: 32px 0; }
    @media (max-width: 850px) {
      .info__title {
        margin: 16px 0; } }
  @media (min-width: 480px) and (max-width: 850px) {
    .info__body {
      display: flex; } }
  .info__body--card {
    margin-bottom: 16px; }
    @media (min-width: 480px) and (max-width: 850px) {
      .info__body--card {
        margin-right: 16px;
        flex: 2 1; } }
  @media (min-width: 480px) and (max-width: 850px) {
    .info__body--accordion {
      flex: 3 1; } }

.questions {
  display: flex;
  width: 100%;
  flex-direction: column; }
  @media (max-width: 850px) {
    .questions {
      flex-direction: column; } }
  .questions__title {
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
    margin: 32px 0; }
    @media (max-width: 850px) {
      .questions__title {
        margin: 16px 0; } }

.order {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2; }
  @media (max-width: 1030px) {
    .order {
      position: static; } }
