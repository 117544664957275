/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.event-description {
  margin: 0;
  z-index: 2;
  width: 100%;
  resize: none;
  display: block;
  padding: 8px 16px;
  cursor: pointer;
  font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
  border-radius: 3px;
  position: relative;
  -webkit-appearance: none;
  color: #3d3e42;
  background-color: #ffffff;
  border: 2px solid #eeeef3; }
  .event-description__header {
    display: flex;
    justify-content: space-between; }
  .event-description__svg {
    height: 12px;
    width: 12px; }
  .event-description__description {
    margin: 15px 0 0 0; }
  .event-description textarea {
    white-space: normal; }
