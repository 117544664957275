/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.svg {
  display: inline-block;
  width: 16px; }
  .svg svg {
    display: block;
    width: 100%;
    height: auto; }
