$colors: (
    redness:#de102d,
    redlight: #ff3e43,
    purple: #8a55ea,//
    salmon: #fc7664,//
    salmon-hover: #ff927c,//
    dark-anthracite: #3d3e42,
    mid-anthracite: #848692,
    blue-silver: #aaaabd,
    light-anthracite: #dadae4,
    light-grey: #eeeef3,
    athens-grey: #fafafb,
    white: #ffffff,
    water-yellow: #f5b56a,
    water-red: #fb6f75,
    water-blue: #779ee0,
    water-green: #81dbbe,
    water-purple: #9194bd,
    greydient: linear-gradient(to bottom, #000000, #323332),
);
