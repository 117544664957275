/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.group-edit__event-includes {
  padding: 16px;
  background-color: #fafafb;
  width: 100%;
  margin-bottom: 16px; }
