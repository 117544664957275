/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.tabs {
  width: 100%;
  font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
  display: block;
  color: #3d3e42;
  padding: 32px 0px 32px 0px; }
  .tabs__less-padding {
    padding: 16px 0px 32px 0px; }
  .tabs__list {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    list-style-type: none;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden; }
    .tabs__list-item {
      padding: 0px; }
  .tabs__link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: #3d3e42;
    padding: 0px 16px;
    white-space: nowrap; }
    .tabs__list-item:first-child .tabs__link {
      padding-left: 0px; }
    .tabs__link:hover {
      text-decoration: none; }
    .tabs__link--active {
      color: #de102d; }
