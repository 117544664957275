/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.tooltip {
  display: inline-block; }
  .tooltip-content {
    height: 0px;
    line-height: 0px; }
    .tooltip-content p {
      margin-bottom: 8px; }
      .tooltip-content p:last-child {
        margin-bottom: 0; }

.light .react-tooltip-lite {
  background: #ffffff;
  color: #3d3e42; }

.light .react-tooltip-lite-arrow {
  border-color: #ffffff; }

.react-tooltip-lite {
  font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
  border-radius: 3px;
  box-shadow: 0px 3px 6px 0 rgba(10, 9, 12, 0.19); }

.light .react-tooltip-lite {
  background: #ffffff;
  color: #3d3e42; }

.light .react-tooltip-lite-arrow {
  border-color: #ffffff; }

.dark .react-tooltip-lite {
  background: #3d3e42;
  color: #ffffff; }

.dark .react-tooltip-lite-arrow {
  border-color: #3d3e42; }
