/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.collapse {
  position: relative;
  display: block;
  width: 100%; }
  .collapse[aria-expanded="false"] ~ .repeater__item-remove {
    display: none; }
  .collapse__toggle {
    position: absolute;
    top: 5px;
    right: -43px; }
    .collapse__toggle .button__icon svg {
      fill: #3d3e42; }
    .collapse[aria-expanded="true"] .collapse__toggle {
      transform: rotate(180deg); }
  .collapse__content {
    display: block;
    width: 100%; }
    .collapse__content-visible {
      display: block;
      width: 100%; }
    .collapse__content-expandable {
      display: none;
      width: 100%;
      padding: 16px 0 0; }
      .collapse[aria-expanded="true"] .collapse__content-expandable {
        display: block; }
  .collapse .input--required:after {
    right: -10px; }
