/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.filtertags {
  padding: 0;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  align-content: center;
  list-style-type: none;
  margin: 0 0 16px 0;
  justify-content: flex-start; }
  .filtertags__item {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    border-radius: 3px;
    flex-direction: row;
    align-items: center;
    align-content: center;
    margin-right: 8px;
    margin-bottom: 8px;
    justify-content: flex-start;
    color: #848692;
    padding: 2px 8px 2px 16px;
    background-color: #eeeef3; }
  .filtertags__label:first-letter {
    text-transform: uppercase; }
  .filtertags__clear {
    margin-left: 8px;
    margin-bottom: 8px; }
  .filtertags__btn {
    padding: 2px;
    border: none;
    cursor: pointer;
    border-radius: 0;
    margin: 0 0 0 8px;
    -webkit-appearance: none;
    background-color: transparent;
    color: #848692; }
    .filtertags__btn .svg,
    .filtertags__btn svg {
      width: 8px;
      height: 8px;
      display: block;
      fill: #848692; }
  .filtertags__btn-clear {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    border-radius: 0;
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    text-decoration: none;
    -webkit-appearance: none;
    color: #de102d;
    background-color: transparent; }
    .filtertags__btn-clear:hover {
      text-decoration: underline; }
