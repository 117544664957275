/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.comments {
  position: relative;
  margin: 0 0 24px 0;
  padding: 0px; }
  .comments .notification {
    margin: 0px 0px 24px 0px; }
  .comments .element-loader {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10; }
  .comments__content {
    position: relative;
    max-height: 36vh;
    margin: 0px 0px 24px 0px;
    padding: 0px 16px 0px 0px;
    overflow: hidden;
    overflow-y: auto; }
    @media (max-width: 1030px) {
      .comments__content {
        max-height: none;
        padding: 0px; } }
  .comments__comment {
    position: relative;
    list-style-type: none;
    border-bottom: 1px solid #eeeef3;
    padding: 8px 32px 8px 0px; }
    .comments__comment:first-child {
      border-top: 1px solid #eeeef3; }
    .comments__comment-user {
      font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif;
      margin-bottom: 8px; }
      .comments__comment-user-icon {
        display: inline-block;
        width: 12px;
        margin-right: 4px; }
        .comments__comment-user-icon svg {
          display: block;
          width: 100%;
          height: auto;
          fill: #848692; }
    .comments__comment-date {
      font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
      color: #848692;
      margin-left: 8px; }
    .comments__comment-content {
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
    .comments__comment-delete {
      position: absolute;
      bottom: 8px;
      right: 0px;
      z-index: 1;
      display: flex;
      cursor: pointer;
      border-radius: 0;
      flex-wrap: nowrap;
      text-align: center;
      align-items: center;
      width: 14px;
      align-content: center;
      text-decoration: none;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0;
      -webkit-appearance: none;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
      .comments__comment-delete svg {
        fill: #3d3e42;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
      .comments__comment-delete:hover svg {
        fill: #de102d; }
  .comments__view-all {
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    color: #de102d;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    margin: 0px;
    padding: 0px;
    -webkit-appearance: none; }
    .comments__view-all:hover {
      color: #ff3e43;
      text-decoration: none; }
    .comments__view-all:active {
      color: #ff3e43;
      text-decoration: none; }
