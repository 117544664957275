/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

@media (max-width: 850px) {
  .sales-overview-widget .widget__content {
    overflow: auto; } }

.sales-overview-widget__content {
  display: flex;
  width: 100%;
  height: 296px;
  flex-basis: 100%;
  flex-direction: row; }
  @media (max-width: 850px) {
    .sales-overview-widget__content {
      width: 800px;
      min-width: 800px;
      max-width: 800px; } }
