/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 24px; }

h1 {
  font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif; }

.font-1 {
  font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif; }

h2 {
  font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif; }

.font-2 {
  font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif; }

h3,
.font-3 {
  font: 700 16px/20px "Roboto", Helvetica, Arial, sans-serif; }

h4 {
  font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }

.font-4 {
  font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif; }

h5,
h6,
.font-5 {
  font: 700 12px/18px "Roboto", Helvetica, Arial, sans-serif; }

.font-form {
  font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif; }

.font-button {
  font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif; }

.font-body {
  font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif; }

.font-list {
  font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }

.font-label {
  font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif; }

.font-caption {
  font: 700 10px/14px "Roboto", Helvetica, Arial, sans-serif; }

.color-brand {
  color: #de102d; }
