/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.notification {
  width: 100%;
  display: flex;
  overflow: hidden;
  text-align: left;
  position: relative;
  border-style: solid;
  border-width: 2px;
  border-color: #f5b56a;
  border-radius: 3px;
  -webkit-appearance: none;
  margin-bottom: 16px;
  padding: 16px;
  background-color: transparent;
  opacity: 1;
  transition: all 0.25s ease-in-out; }
  .notification:last-child {
    margin-bottom: 0px; }
  .notification__title {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0 0 8px 0;
    color: #3d3e42; }
  .notification__text {
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0 0 16px 0;
    color: #848692; }
  .notification__icon {
    display: inline-block;
    margin-right: 8px;
    width: 20px;
    min-width: 20px;
    fill: #f5b56a; }
  .notification .button {
    position: relative;
    height: 28px;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    color: #848692;
    padding: 5px 8px;
    margin: 0 8px 0 0;
    background-color: transparent; }
    .notification .button:before {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 3px;
      z-index: 1;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      border: 2px solid #dadae4; }
    .notification .button__icon {
      width: 16px;
      height: 16px;
      vertical-align: middle; }
    .notification .button__icon, .notification .button__label {
      margin-right: 4px; }
    .notification .button:hover:before {
      border: 2px solid #eeeef3; }
  .notification__close {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    padding: 8px;
    font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin: 0;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    cursor: pointer; }
    .notification__close-icon {
      display: block;
      width: 8px;
      height: 8px;
      fill: #3d3e42; }
    .notification__close:hover .notification__close-icon, .notification__close:active .notification__close-icon, .notification__close:focus .notification__close-icon {
      fill: #ff3e43; }
  .notification--success {
    border-color: #81dbbe; }
    .notification--success .notification__icon {
      fill: #81dbbe; }
  .notification--warning {
    border-color: #f5b56a; }
    .notification--warning .notification__icon {
      fill: #f5b56a; }
  .notification--error {
    border-color: #fb6f75; }
    .notification--error .notification__icon {
      fill: #fb6f75; }
  .notification--info {
    border-color: #779ee0; }
    .notification--info .notification__icon {
      fill: #779ee0; }
  .floating-notifications .notification {
    color: #ffffff;
    background-color: #81dbbe; }
    .floating-notifications .notification__content {
      width: 100%;
      padding-right: 16px; }
    .floating-notifications .notification__close {
      display: block; }
      .floating-notifications .notification__close-icon {
        fill: #ffffff; }
      .floating-notifications .notification__close:hover .floating-notifications .notification__close-icon, .floating-notifications .notification__close:active .floating-notifications .notification__close-icon, .floating-notifications .notification__close:focus .floating-notifications .notification__close-icon {
        fill: #ff3e43; }
    .floating-notifications .notification__title {
      color: #ffffff; }
    .floating-notifications .notification__text {
      color: #ffffff; }
    .floating-notifications .notification__icon {
      fill: #ffffff; }
    .floating-notifications .notification--success {
      background-color: #81dbbe; }
      .floating-notifications .notification--success .notification__icon {
        fill: #ffffff; }
    .floating-notifications .notification--warning {
      background-color: #f5b56a; }
      .floating-notifications .notification--warning .notification__icon {
        fill: #ffffff; }
    .floating-notifications .notification--error {
      background-color: #fb6f75; }
      .floating-notifications .notification--error .notification__icon {
        fill: #ffffff; }
    .floating-notifications .notification--info {
      background-color: #779ee0; }
      .floating-notifications .notification--info .notification__icon {
        fill: #ffffff; }
    .floating-notifications .notification--transition-enter {
      opacity: 0.01;
      transform: translateX(-16px); }
      .floating-notifications .notification--transition-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 500ms ease-in, transform 500ms ease-in; }
    .floating-notifications .notification--transition-leave {
      opacity: 1;
      transform: translateX(0); }
      .floating-notifications .notification--transition-leave-active {
        opacity: 0.01;
        transform: translateX(-16px);
        transition: opacity 300ms ease-in, transform 300ms ease-in; }
