/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.hero {
  position: relative;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 24px;
  background-image: linear-gradient(to bottom, #000000, #323332); }
  @media (max-width: 1030px) {
    .hero {
      width: 100%;
      height: auto; } }
  .hero__content {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding-bottom: 80px;
    z-index: 10; }
    @media (max-width: 1030px) {
      .hero__content {
        padding-bottom: 64px; } }
  .hero__logo {
    display: block;
    width: 190px;
    max-width: 328px;
    height: auto;
    margin: 0 auto 16px auto; }
    @media (max-width: 1030px) {
      .hero__logo {
        width: 120px; } }
    .hero__logo svg {
      display: block;
      width: 100%;
      height: auto;
      fill: #ffffff; }
  .hero__headline {
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    text-align: center; }
  .hero__footer {
    position: absolute;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1; }
    @media (max-width: 1030px) {
      .hero__footer {
        bottom: 32px; } }
    @media (max-width: 1030px) {
      .hero__footer .hero__logo {
        margin: 0px; } }
  .hero__background {
    position: absolute;
    display: block;
    width: 70%;
    max-width: 496px;
    height: auto;
    fill: #dadae4;
    opacity: 0.5;
    z-index: 1; }
    .hero__background svg {
      display: block;
      width: 100%;
      height: auto; }
