/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.repeater {
  position: relative;
  display: block;
  width: 100%; }
  .repeater--loading {
    cursor: progress; }
  .repeater__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 3; }
  .repeater__list {
    display: block;
    width: 100%; }
    .repeater--dragging .repeater__list {
      cursor: -webkit-grabbing;
      cursor: grabbing; }
    .repeater--loading .repeater__list {
      pointer-events: none; }
  .repeater__item {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
    border-radius: 3px;
    border: 2px solid #eeeef3;
    padding: 16px 40px 16px 40px;
    margin: 0 0 16px;
    background: #ffffff;
    opacity: 1; }
    .repeater--dragging .repeater__item:not(.repeater__item--dragged) {
      opacity: 0.5; }
    .repeater__item-move {
      position: absolute;
      top: 8px;
      left: 4px;
      padding: 8px;
      cursor: -webkit-grab;
      cursor: grab; }
      .repeater--dragging .repeater__item-move {
        cursor: -webkit-grabbing;
        cursor: grabbing; }
      .repeater--no-sort .repeater__item-move {
        display: none; }
      .repeater__item-move-icon {
        display: block;
        width: 16px;
        height: 16px; }
        .repeater__item-move-icon svg {
          display: block;
          width: 16px;
          height: 16px; }
    .repeater__item-cancel {
      position: absolute;
      top: 5px;
      right: 5px; }
      .repeater__item-cancel .button__icon svg {
        fill: #3d3e42; }
      .repeater__item-cancel:hover .button__icon svg {
        fill: #ff3e43; }
    .repeater__item-remove {
      position: absolute;
      bottom: 5px;
      right: 5px; }
    .repeater__item-content {
      display: block;
      width: 100%; }
  .repeater__add-new--right {
    float: right;
    padding-right: 0; }
