/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.react-select--is-disabled {
  pointer-events: none; }
  .react-select--is-disabled .react-select__control {
    background-color: #eeeef3;
    border: 2px solid #eeeef3; }
  .react-select--is-disabled .react-select__indicators {
    background-color: #eeeef3; }
  .react-select--is-disabled + .input__label {
    background: transparent; }

.react-select--loading .react-select__single-value {
  background: #eeeef3;
  color: #eeeef3;
  padding: 0 8px;
  margin-top: 8px;
  border-radius: 3px;
  overflow: hidden;
  display: inline-block; }

.react-select--multi {
  position: relative; }

.react-select__loader {
  position: absolute;
  right: 40px;
  top: 16px;
  z-index: 9;
  background: #ffffff; }
  .react-select__loader--disabled {
    background-color: #eeeef3; }
  .react-select--action ~ .react-select__loader {
    right: 96px; }

.react-select__loader--disabled {
  background-color: #eeeef3; }

.react-select__control {
  margin: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  resize: none;
  display: block;
  font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif;
  padding: 0 8px 0 16px;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  font-weight: normal;
  -webkit-appearance: none;
  color: #3d3e42;
  background-color: #ffffff;
  border: 2px solid #eeeef3; }
  .react-select--multi .react-select__control {
    min-height: 40px;
    height: auto; }
  .input--error .react-select__control {
    border: 2px solid #fb6f75; }

.react-select__control--menu-is-open {
  border: 2px solid #848692; }
  .react-select__control--menu-is-open .react-select__dropdown-indicator {
    transform: rotate(180deg); }

.react-select__input {
  top: 0;
  bottom: 0;
  left: 16px;
  right: 32px;
  position: absolute; }
  .react-select--multi .react-select__input {
    position: static;
    min-height: 36px;
    height: 100%; }
  .react-select__input input {
    height: 100%;
    display: block; }

.react-select__value-container {
  display: block; }
  .react-select__value-container--is-multi {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
    padding-right: 50px; }

.react-select__single-value {
  display: block;
  padding: 10px 46px 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.react-select__multi-value {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  margin: 9px 8px 0 0;
  padding: 0 32px 0 16px;
  font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
  color: #eeeef3;
  background: #848692; }
  .react-select__multi-value:last-child {
    margin-right: 0; }
  .react-select__multi-value__label {
    display: block;
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .react-select__multi-value__remove {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .react-select__multi-value__remove:hover {
      background: #de102d; }
    .react-select__multi-value__remove svg {
      display: block;
      width: 16px;
      height: 16px;
      fill: #eeeef3; }

.react-select__indicators {
  display: flex;
  top: 0;
  right: 8px;
  bottom: 2px;
  position: absolute;
  transform: rotate(0deg);
  z-index: 2;
  background: #ffffff; }

.react-select__dropdown-indicator, .react-select__clear-indicator {
  height: 100%;
  padding: 0 8px;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  text-align: center;
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
  .react-select--multi .react-select__dropdown-indicator, .react-select--multi .react-select__clear-indicator {
    display: flex;
    align-self: flex-start;
    height: auto; }
  .react-select__dropdown-indicator .svg, .react-select__clear-indicator .svg {
    width: auto; }
  .react-select__dropdown-indicator svg, .react-select__clear-indicator svg {
    width: 8px;
    display: block;
    height: 36px; }

.react-select__dropdown-indicator {
  fill: #3d3e42; }

.react-select__clear-indicator {
  fill: #848692; }

.react-select__menu {
  left: 0;
  right: 0;
  top: 40px;
  font-size: 16px;
  position: absolute;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(49, 69, 141, 0.1);
  z-index: 100; }
  .react-select--multi .react-select__menu {
    top: 100%; }
  .react-select__menu-list {
    padding: 0px !important; }

.react-select__menu-notice {
  display: block;
  padding: 10px 20px; }

.react-select__option {
  display: block;
  cursor: pointer;
  padding: 10px 8px 10px 20px;
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
  color: #aaaabd;
  background-color: transparent;
  text-align: left;
  white-space: normal; }
  .react-select__option:hover {
    color: #848692;
    background-color: #eeeef3; }

.react-select__option--is-focused, .react-select__option--is-selected {
  color: #848692;
  background-color: #eeeef3; }

.react-select--action {
  display: inline-block;
  width: calc(100% - 56px);
  max-width: calc(100% - 56px);
  vertical-align: top; }
