/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.filter {
  position: relative;
  display: inline-block; }
  .filter__btn {
    margin: 0;
    padding: 0;
    width: auto;
    border: none;
    display: flex;
    cursor: pointer;
    border-radius: 0;
    flex-wrap: nowrap;
    font: 400 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    align-items: center;
    flex-direction: row;
    align-content: center;
    -webkit-appearance: none;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    justify-content: flex-start;
    color: #aaaabd;
    background-color: transparent; }
    .filter__btn-icon {
      width: 16px;
      height: 16px;
      margin-right: 16px; }
      @media (max-width: 1030px) {
        .filter__btn-icon {
          margin-right: 0; } }
      .filter__btn-icon svg {
        width: 100%;
        display: block;
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
        fill: #aaaabd; }
    .filter__btn-label {
      margin-top: 3px; }
      @media (max-width: 1030px) {
        .filter__btn-label {
          display: none; } }
    .filter__btn:hover .filter__btn-icon svg {
      fill: #3d3e42; }
  .filter__dropdown {
    top: 100%;
    z-index: 10;
    width: 300px;
    display: none;
    position: absolute;
    border-radius: 3px;
    left: -16px;
    padding: 16px;
    margin-top: 16px;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }
    @media (max-width: 480px) {
      .filter__dropdown {
        left: -26px;
        width: calc(100vw + 2px); } }
    .filter__dropdown:before {
      top: -5px;
      left: 56px;
      z-index: 1;
      width: 14px;
      height: 14px;
      content: '\A';
      position: absolute;
      pointer-events: none;
      transform: rotate(45deg);
      background-color: #ffffff;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1); }
      @media (max-width: 1030px) {
        .filter__dropdown:before {
          left: 17px; } }
      @media (max-width: 480px) {
        .filter__dropdown:before {
          left: 27px; } }
    .filter__dropdown:after {
      left: 0;
      top: 2px;
      right: 2px;
      z-index: 2;
      content: '\A';
      position: absolute;
      pointer-events: none;
      height: 16px;
      background-color: #ffffff; }
  .filter__dropdown-header {
    padding: 0;
    width: 100%;
    display: flex;
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    align-content: center;
    margin: 0 0 16px 0;
    justify-content: space-between;
    color: #3d3e42; }
  .filter__dropdown-header-col {
    display: block; }
  .filter__dropdown-close {
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    border: none;
    display: flex;
    cursor: pointer;
    border-radius: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    -webkit-appearance: none;
    background-color: transparent; }
    .filter__dropdown-close .svg,
    .filter__dropdown-close svg {
      width: 8px;
      height: 8px;
      display: block;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
      fill: #848692; }
    .filter__dropdown-close:hover svg {
      fill: #3d3e42; }
  .filter--opened .filter__btn {
    color: #3d3e42; }
    .filter--opened .filter__btn-icon svg {
      fill: #3d3e42; }
  .filter--opened .filter__dropdown {
    display: block; }
