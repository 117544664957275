/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.table-actions {
  width: 26px;
  height: 26px;
  float: right;
  display: block;
  cursor: pointer;
  position: relative; }
  .table-actions:hover .table-actions__dots {
    background-color: #eeeef3; }
  .table-actions:hover .table-actions__items {
    opacity: 1;
    visibility: visible;
    pointer-events: all; }
  .table-actions__dots {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    background-color: transparent; }
    .table-actions__dots svg {
      width: 4px;
      margin: 0 auto;
      display: block;
      fill: #aaaabd; }
  .table-actions__items {
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    visibility: hidden;
    align-items: center;
    pointer-events: none;
    align-content: center;
    list-style-type: none;
    transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
    justify-content: flex-start;
    padding-right: 32px; }
  .table-actions__item {
    padding-left: 12px;
    background-color: #ffffff; }
    .table-actions__item a,
    .table-actions__item button,
    .table-actions__item .button {
      display: flex;
      cursor: pointer;
      border-radius: 0;
      flex-wrap: nowrap;
      text-align: center;
      align-items: center;
      width: 16px;
      height: 24px;
      align-content: center;
      text-decoration: none;
      justify-content: center;
      border: none;
      background-color: transparent;
      padding: 0px;
      -webkit-appearance: none; }
      .table-actions__item a svg,
      .table-actions__item button svg,
      .table-actions__item .button svg {
        transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95);
        fill: #3d3e42; }
      .table-actions__item a:hover svg,
      .table-actions__item button:hover svg,
      .table-actions__item .button:hover svg {
        fill: #de102d; }
