/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.notif {
  position: relative;
  flex: 1 1;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  margin: 0 0 16px 0;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(102, 103, 112, 0.42);
  padding: 16px; }
  .notif__title {
    display: block;
    width: 100%;
    font: 700 18px/22px "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
    padding-right: 80px; }
  .notif__subtitle {
    display: block;
    width: 100%;
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    letter-spacing: -0.4px;
    margin-bottom: 8px;
    padding-right: 80px; }
  .notif__status {
    font: 400 11px/16px "Roboto", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 4px 8px;
    z-index: 1; }
  .notif__notification-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px; }
    .notif__notification-content:last-child {
      margin-bottom: 0px; }
  .notif__action-info {
    margin-bottom: 40px;
    margin-top: 16px;
    display: flex; }
    .notif__action-info_bold {
      font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
      margin: 0 8px; }
  .notif__updated, .notif__date {
    color: #848692;
    margin: 0px; }
  .notif__actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap; }
    .notif__actions-secondary .button {
      padding: 0;
      margin-right: 16px;
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
