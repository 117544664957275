/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.ReactModal__Overlay {
  z-index: 10000;
  color: #3d3e42;
  background-color: rgba(0, 0, 0, 0.4) !important; }

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 24px;
  align-content: center;
  justify-content: center; }
  .modal__inner {
    z-index: 2;
    width: 100%;
    max-height: 100vh;
    max-width: 800px;
    text-align: left;
    position: relative;
    padding: 56px;
    background-color: #ffffff;
    overflow: auto; }
    .modal__inner--small {
      max-width: 352px; }
  .modal__header {
    margin-bottom: 24px; }
  .modal__title {
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif;
    margin-bottom: 0px; }
  .modal__subtitle {
    font: 400 13px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin-top: 8px; }
  .modal__close {
    margin: 0;
    padding: 0;
    z-index: 10;
    border: none;
    cursor: pointer;
    top: 24px;
    position: absolute;
    right: 24px;
    -webkit-appearance: none;
    background-color: transparent; }
    .modal__close svg {
      fill: #aaaabd;
      transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
    .modal__close:hover svg {
      fill: #de102d; }
