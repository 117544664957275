/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.total-tickets-24h-widget {
  flex-direction: column;
  width: 100%;
  display: flex; }
  .total-tickets-24h-widget .doughnut-chart {
    width: 80%;
    margin: 0 auto;
    min-height: 250px; }
  .total-tickets-24h-widget__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none; }
  .total-tickets-24h-widget__data {
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
    text-align: center; }
  .total-tickets-24h-widget__total {
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif;
    margin-bottom: 8px; }
  .total-tickets-24h-widget__item {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center; }
    .total-tickets-24h-widget__item-icon {
      display: flex;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%; }
    .total-tickets-24h-widget__item-label {
      display: flex;
      font: 400 12px/18px "Roboto", Helvetica, Arial, sans-serif; }
  .total-tickets-24h-widget .element-loader {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    margin-left: -17px;
    margin-top: -4px; }
  .total-tickets-24h-widget__statistic-value {
    margin-bottom: 0;
    font: 700 26px/26px "Roboto", Helvetica, Arial, sans-serif;
    display: flex;
    align-items: center; }
  .total-tickets-24h-widget__statistic-difference {
    font: 700 14px/18px "Roboto", Helvetica, Arial, sans-serif;
    margin-left: 8px;
    margin-top: 4px; }
  .total-tickets-24h-widget__title {
    margin-bottom: 0;
    display: flex;
    align-items: center; }
  .total-tickets-24h-widget__wrap {
    position: relative; }
