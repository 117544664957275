/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.bar-chart {
  display: block;
  height: 100%;
  width: 100%;
  position: relative; }
