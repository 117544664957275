/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.status-badge {
  position: relative;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 17px;
  display: inline-block;
  padding: 0 4px;
  margin: 0 4px;
  border-radius: 3px;
  background: #f5b56a;
  color: #ffffff; }
  .status-badge--new {
    background: #f5b56a;
    color: #ffffff; }
  .status-badge--voided, .status-badge--declined {
    background: #fb6f75;
    color: #ffffff; }
  .status-badge--pending, .status-badge--contract_pending, .status-badge--draft {
    background: #779ee0;
    color: #ffffff; }
  .status-badge--accepted, .status-badge--fulfilled, .status-badge--approved, .status-badge--paid {
    background: #81dbbe; }
  .status-badge--special, .status-badge--payment_pending {
    background: #9194bd;
    color: #ffffff; }
  .status-badge--admin {
    background: #3d3e42; }
  .list .status-badge {
    flex-shrink: 0;
    margin: 8px 16px 0 0; }
