/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.sort-events-repeater-item {
  font: 400 16px/20px "Roboto", Helvetica, Arial, sans-serif; }
