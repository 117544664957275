/* Variables & Mixins */
.clearfix {
  *zoom: 1; }

.clearfix:before,
.clearfix:after {
  display: table;
  content: ''; }

.clearfix:after {
  clear: both; }

.search {
  width: 100%;
  display: block;
  position: relative;
  border-radius: 3px;
  transition: all 0.15s cubic-bezier(0.45, 0.03, 0.5, 0.95); }
  .search:hover {
    box-shadow: 0 0 4px -2px #aaaabd; }
  .search__input {
    width: 100%;
    border: none;
    display: block;
    font-size: 14px;
    border-radius: 3px;
    height: 32px;
    -webkit-appearance: none;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    color: #3d3e42;
    padding: 8px 24px 8px 48px; }
    .search__input::-ms-clear, .search__input::-ms-reveal {
      display: none; }
    .search__input::-webkit-input-placeholder {
      color: #aaaabd; }
    .search__input:-moz-placeholder {
      color: #aaaabd; }
    .search__input::-moz-placeholder {
      color: #aaaabd; }
    .search__input:-ms-input-placeholder {
      color: #aaaabd; }
    .search__input.placeholder {
      color: #aaaabd; }
    @media (max-width: 1030px) {
      .search__input {
        width: 40px;
        padding-left: 32px; } }
  .search__icon {
    top: 0;
    left: 16px;
    position: absolute;
    pointer-events: none;
    transform: translateY(50%); }
    .search__icon svg {
      width: 16px;
      height: 16px;
      display: block;
      fill: #aaaabd; }
  .search__close {
    top: 50%;
    z-index: 2;
    border: none;
    padding: 5px;
    display: none;
    cursor: pointer;
    right: 8px;
    border-radius: 0;
    position: absolute;
    margin: -9px 0 0 0;
    -webkit-appearance: none;
    background-color: transparent; }
    .search__close .svg,
    .search__close svg {
      width: 8px;
      height: 8px;
      display: block; }
    .search__close svg {
      fill: #aaaabd; }
  .search--focused .search__icon svg {
    fill: #3d3e42; }
  .search--active {
    box-shadow: 0 0 4px -2px #aaaabd; }
    .search--active .search__icon svg {
      fill: #3d3e42; }
    .search--active .search__close {
      display: block; }
